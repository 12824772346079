import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiServicesService } from '../../../Services/api-services.service'
import { AlertService } from '../../common/Alert/alert/alert.services';
import { AppConfig } from '../../../appConfig/app.config';
@Component({
  selector: 'app-volunteers-edit',
  templateUrl:'./volunteers-edit.component.html',
  styleUrls: ['./volunteers-edit.component.scss']
})
export class VolunteersEditComponent implements OnInit {
  userid: any;
  volunteertype=[{'id':1,'Name':'Organizer'},{'id':2,'Name':'EventCrew'},{'id':3,'Name':'Volunteer'},{'id':4,'Name':'StudentAmbassador'},{'id':5,'Name':'Advisers'},{'id':6,'Name':'Co-Chairs'}]
  formData = new FormData()
  user_avatar: any
  IsLoading=false;
  submitted = false;
  VolunteersEditForm: UntypedFormGroup;
  public sidebarMinimized = false;

  photo: any
  buttonEnable = false

  constructor(private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private apiCall: ApiServicesService,
    private router: Router,
    private alert: AlertService,
    private appconfig:AppConfig) { }

  ngOnInit(): void {
    this.userid = this.route.snapshot.paramMap.get('userid');
    this.GetVolunteer()

    this.VolunteersEditForm = this.formBuilder.group({
      id: new UntypedFormControl(''),
      firstName: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      designation: new UntypedFormControl('', Validators.required),
      volunteertype: new UntypedFormControl('',Validators.required),
      organization: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      twitterUrl: new UntypedFormControl(''),
      linkedinUrl: new UntypedFormControl(''),
      photoPath: new UntypedFormControl(''),
      isActive: new UntypedFormControl(true),
      createdBy: new UntypedFormControl(),
      eventYear: new UntypedFormControl(),
      createdDate: new UntypedFormControl(new Date()),
      emailId:new UntypedFormControl('',[Validators.required,Validators.pattern("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")]),
      mobileNumber:new UntypedFormControl('',Validators.required)
    })


  }
  get Volunteer() { return this.VolunteersEditForm.controls; }


  GetVolunteer() {
    this.IsLoading=true;
    this.apiCall.GetVolunteers(this.userid).subscribe(res => {


      this.VolunteersEditForm.get('id').setValue(res.id)
      this.VolunteersEditForm.get('firstName').setValue(res.firstName)
      this.VolunteersEditForm.get('lastName').setValue(res.lastName)
      this.VolunteersEditForm.get('designation').setValue(res.designation)
      this.VolunteersEditForm.get('organization').setValue(res.organization)
      this.VolunteersEditForm.get('description').setValue(res.description)
      this.VolunteersEditForm.get('twitterUrl').setValue(res.twitterUrl)
      this.VolunteersEditForm.get('volunteertype').setValue(res.volunteerType)
      this.VolunteersEditForm.get('linkedinUrl').setValue(res.linkedInUrl)
      this.VolunteersEditForm.get('isActive').setValue(res.isActive)
      this.VolunteersEditForm.get('photoPath').setValue(res.photoPath)
      this.VolunteersEditForm.get('createdBy').setValue(localStorage.getItem('UserId'))
      this.VolunteersEditForm.get('eventYear').setValue(this.appconfig.GetEventYear)
      this.VolunteersEditForm.get('createdDate').setValue(new Date())
      this.VolunteersEditForm.get('emailId').setValue(res.emailId)
      this.VolunteersEditForm.get('mobileNumber').setValue(res.mobileNumber)
      this.user_avatar = res.photoPath
      this.IsLoading=false;
    })
    this.IsLoading=false;
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  updatevolunteers() {
    if (this.VolunteersEditForm.invalid) {
      return;
    }
    this.IsLoading=true;
    this.formData.append('VolunteersForm', JSON.stringify(this.VolunteersEditForm.value));
    this.apiCall.volunteersUpdate(this.formData).subscribe(res => {

      if (res != null || res != undefined) {
        this.IsLoading=false;
        this.alert.success("Volunteers Added!")
        this.router.navigate(['/ListVolunteers'])

      }
      else {
        error => {
          this.alert.error(error)
        }
        this.IsLoading=false;
      }
    })
  }


  volunterImage(image: FileList) {
    const img = image[0].name.split('.').pop();
    if (img === 'png' || img === 'PNG' || img === 'jpg' || img === 'JPG' || img === 'jpeg' || img === 'gif' || img === 'psd' || img === 'bmp') {
      this.IsLoading=true;
      for (let _files in image) {
        if (_files != "length" && _files != "item") {
          this.photo = image.item(Number(_files));

          this.formData.append("photo", this.photo)
          this.formData.append("isImageUpdated", "true")
          this.IsLoading=false;
        }
        this.IsLoading=false;
      }

    }
    else {

    }

  }
}
