import { Component, OnInit } from '@angular/core';
import { ApiServicesService } from '../../../Services/api-services.service';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppConfig } from '../../../appConfig/app.config';

import { AlertService } from '../../common/Alert/alert';
import { Router } from '@angular/router';
@Component({
  selector: 'app-communities-edit',
  templateUrl: './communities-edit.component.html',
  styleUrls: ['./communities-edit.component.scss']
})
export class CommunitiesEditComponent implements OnInit {
  editCommunitiesForm: UntypedFormGroup;
  Communitytype=[{'id':1,'Name':'Azure'},{'id':2,'Name':'OpenSource'},{'id':3,'Name':'Diversity'}]
  formData = new FormData()
  user_avatar: any
  photo:any
  submitted = false
  IsLoading=false
  options = {
    autoClose: true
  };
  constructor(
    private apicall: ApiServicesService,
    private formBuilder: UntypedFormBuilder,
    private appConfig: AppConfig,
    private alert :AlertService,
    private router:Router
  ) { }

  ngOnInit() {

    this.editCommunitiesForm = this.formBuilder.group({
      id:new UntypedFormControl(''),
      name: new UntypedFormControl('', Validators.required),
      about: new UntypedFormControl('', Validators.required),
      twitterUrl: new UntypedFormControl(''),
      linkedinUrl: new UntypedFormControl(''),
      logoPath: new UntypedFormControl(''),
      communityType: new UntypedFormControl(1, Validators.required),
      location: new UntypedFormControl(''),
      websiteUrl: new UntypedFormControl('', Validators.required),
      contactPerson:new UntypedFormControl('',Validators.required),
      emailId:new UntypedFormControl('',Validators.required),
      mobileNumber:new UntypedFormControl('',Validators.required),
      isActive: new UntypedFormControl(),
      createdBy: new UntypedFormControl(localStorage.getItem('UserId')),
      eventYear: new UntypedFormControl(this.appConfig.GetEventYear),
      createdDate: new UntypedFormControl(new Date())
    })
    this.getdata()
  }

  get editCommunities() { return this.editCommunitiesForm.controls; }


  getdata() {
    this.apicall.shareddata.subscribe(data => {
      for (let listdata of data) {
        this.editCommunitiesForm.get('name').setValue(listdata.name)
        this.editCommunitiesForm.get('about').setValue(listdata.about)
        this.editCommunitiesForm.get('twitterUrl').setValue(listdata.twitterUrl)
        this.editCommunitiesForm.get('linkedinUrl').setValue(listdata.linkedInUrl)
        this.editCommunitiesForm.get('communityType').setValue(listdata.communityType)
        this.editCommunitiesForm.get('location').setValue(listdata.location)
        this.editCommunitiesForm.get('websiteUrl').setValue(listdata.websiteUrl)
        this.editCommunitiesForm.get('isActive').setValue(listdata.isActive)
        this.editCommunitiesForm.get('id').setValue(listdata.id)
        this.editCommunitiesForm.get('logoPath').setValue(listdata.logoPath)
        this.editCommunitiesForm.get('contactPerson').setValue(listdata.contactPerson)
        this.editCommunitiesForm.get('emailId').setValue(listdata.emailId)
        this.editCommunitiesForm.get('mobileNumber').setValue(listdata.mobileNumber)
        this.user_avatar = listdata.logoPath
      }




      // this.editCommunitiesForm.get('name').setValue(datas.name)




    })


  }

  updateCommunties() {

    if (this.editCommunitiesForm.invalid) {
      return;
    }

  this.IsLoading=true
    this.formData.append('CommunitiesForm', JSON.stringify(this.editCommunitiesForm.value));

    this.apicall.updateCommunities(this.formData).subscribe(data=>{
      debugger;
      if(data==="true"){
        this.IsLoading=false
        this.router.navigate(['/ListCommunities'])

        this.alert.success('Updated Successfully:)',this.options)


      }
      else
      {
           this.IsLoading=false
           this.alert.error('Something went wrong',this.options)
      }
    error=>{
      this.IsLoading=false
      this.alert.error(error,this.options)



    }



    })

  }

  communitiesImage(image: FileList){
debugger
    const img = image[0].name.split('.').pop();
    if (img === 'png' || img === 'PNG' || img === 'jpg' || img === 'JPG' || img === 'jpeg' || img === 'gif' || img === 'psd' || img === 'bmp') {

      for (let _files in image) {
        if (_files != "length" && _files != "item") {
          this.photo = image.item(Number(_files));

          this.formData.append("photo", this.photo)
          this.formData.append("isImageUpdated", "true")
          this.user_avatar=this.photo[0].name
        }
      }

    }
    else {

    }
  }
 reset(){

 }
}
