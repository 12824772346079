import { Component, OnInit, ViewChild } from "@angular/core"; 
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { PaymentService } from "../../../Services/paymentService.service ";
 
@Component({
  selector: "app-bulkticketlist",
  templateUrl: "./bulkticketlist.component.html",
  styleUrls: ["./bulkticketlist.component.scss"],
})
export class BulkTicketList implements OnInit {
  assignList = [];
  showCopied=false
  responseData: any;
  pageSizes = [5, 10, 20, 50];
  IsLoading = false;
  displayedColumns: string[] = [
    "Merchant OrderId",
    "Company Name",
    "EmailId",
    "Ticket Count",
    "Price Type",
    "Price",
    "Total Price",
    "Gst Amount",
    "Total Amount",
    "Transaction Id",
    "Payment URL",
    "State",
    "Response Code",
    "Payment Type",
    "Success",
    "Code",

  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ShowIndex: any=null;
  constructor(
    private paymentService: PaymentService
  ) {}

  ngOnInit() {
    this.sponserList();
  }

  sponserList() {
    this.IsLoading = true;
    this.paymentService.getBulkTicket().subscribe((res) => {
      this.assignList = res;
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = this.assignList;
      this.dataSource.paginator = this.paginator;
      this.IsLoading = false;
    });
  }
  copyLink(value,i){
    const createEmpty = document.createElement("input");
    document.body.appendChild(createEmpty);
    createEmpty.value =  value;
    createEmpty.select();
    document.execCommand("copy");
    document.body.removeChild(createEmpty);
    this.showCopied = true;
    this.ShowIndex=i
    setTimeout(() => {

      this.showCopied = false;
    }, 1250);


  }
}
