import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'utcToIst'
})
export class UtcToIstPipe implements PipeTransform {
  transform(value: string, format: string = 'DD-MMM-YYYY hh:mm:ss A'): string {
    if (!value) return 'N/A';
    return moment.utc(value).tz('Asia/Kolkata').format(format);
  }
}