import { Component, OnInit } from "@angular/core";
import { ApiServicesService } from "../../Services/api-services.service";
import { AlertService } from "../common/Alert/alert";
@Component({
  selector: "app-event-live",
  templateUrl: "./event-live.component.html",
  styleUrls: ["./event-live.component.scss"],
})
export class EventLiveComponent implements OnInit {
  Day1Data: any;
  trackDetails: any;
  activeIndex: number;
  formData = new FormData();
  options = {
    autoClose: true,
  };
  selectedTrack = "";
  selectedTitle = "";
  selectedSessionId = "";

  constructor(
    private apiCall: ApiServicesService,
    private alert: AlertService
  ) {}

  ngOnInit() {
    this.getDay();
  }

  getDay() {
    this.apiCall.getSessionDetails().subscribe((data) => {
      this.Day1Data = data;
      data.sort((a, b) => a.dateOfEvent.localeCompare(b.dateOfEvent));
      this.eventDetails(data[0].dateOfEvent);
    });
  }

  eventDetails(evendate) {
    this.activeIndex = this.Day1Data.findIndex(
      (f) => f.dateOfEvent == evendate
    );
    const trackDetails = this.Day1Data.filter((f) => f.dateOfEvent == evendate);
    const trackList = trackDetails[0].track.sort((a, b) =>
      this.sortAlphaNum(a.room, b.room)
    );
    this.trackDetails = trackList;
  }

  sortAlphaNum(a, b) {
    let reA = /[^a-zA-Z]/g;
    let reN = /[^0-9]/g;
    var AInt = parseInt(a, 10);
    var BInt = parseInt(b, 10);

    if (isNaN(AInt) && isNaN(BInt)) {
      var aA = a.replace(reA, "");
      var bA = b.replace(reA, "");
      if (aA === bA) {
        var aN = parseInt(a.replace(reN, ""), 10);
        var bN = parseInt(b.replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    } else if (isNaN(AInt)) {
      //A is not an Int
      return 1; //to make alphanumeric sort first return -1 here
    } else if (isNaN(BInt)) {
      //B is not an Int
      return -1; //to make alphanumeric sort first return 1 here
    } else {
      return AInt > BInt ? 1 : -1;
    }
  }

  sendNotifications() {
    if (
      this.selectedTitle !== "" &&
      this.selectedTrack !== "" &&
      this.selectedSessionId !== ""
    ) {
      var data = {
        title: this.selectedTitle,
        trackNumber: this.selectedTrack,
      };
      this.formData.append("sendNotificationsForm", JSON.stringify(data));
      this.apiCall.sendNotifications(data).subscribe((data) => {
        debugger;
        if (data === "true") {
          this.alert.success("Updated Successfully:)", this.options);
        } else {
          this.alert.error("Something went wrong", this.options);
        }
        (error) => {
          this.alert.error(error, this.options);
        };
      });
      this.apiCall
        .UpdateSessionIdInLive(this.selectedTrack, this.selectedSessionId)
        .subscribe(
          (data) => {
            console.log("Updated");
          },
          (err) => {
            console.log("Not updated");
          }
        );
    }
  }

  changeSessionTitle(event, trackName) {
    var track = this.trackDetails.find((f) => f.room == trackName);
    var session = track.sessionDetails.find(
      (f) => f.title == event.target.value
    );
    this.selectedSessionId = session.id;
    this.selectedTitle = event.target.value;
    this.selectedTrack = trackName;
  }
}
