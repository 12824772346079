<app-az-header> </app-az-header>
<app-alert></app-alert>

<app-az-menu></app-az-menu>
<app-loader [Loader]="IsLoading"></app-loader>
<div class="content-wrapper">
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Add Community</h3>
            </div>
            <form [formGroup]="CommunitiesForm" (ngSubmit)="addCommunities()">
              <div class="card-body">
                <div class="form-group">
                  <label>Community Name</label>
                  <input
                    class="form-control"
                    placeholder="Community Name"
                    type="text"
                    formControlName="name"
                    id="name"
                    [ngClass]="{
                      'is-invalid': submitted && Communities.name.errors
                    }"
                    autocomplete="off"
                  />

                  <div
                    *ngIf="submitted && Communities.name.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="Communities.name.errors.required">
                      Community Name is required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>About</label>
                  <input
                    placeholder="About Your Community"
                    type="text"
                    formControlName="about"
                    id="about"
                    [ngClass]="{
                      'is-invalid': submitted && Communities.about.errors
                    }"
                    autocomplete="off"
                    class="form-control"
                  />
                  <div
                    *ngIf="submitted && Communities.about.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="Communities.about.errors.required">
                      About is required
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Select Type</label>
                  <select
                    class="form-control select2"
                    formControlName="communityType"
                  >
                    <option value="null" disabled>Select Type</option>
                    <option
                      *ngFor="let data of Communitytype"
                      [value]="data.id"
                    >
                      {{ data.Name }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label>Website URL</label>
                  <input
                    placeholder="Website URL"
                    type="text"
                    formControlName="websiteUrl"
                    id="websiteUrl"
                    [ngClass]="{
                      'is-invalid': submitted && Communities.websiteUrl.errors
                    }"
                    autocomplete="off"
                    class="form-control"
                  />
                  <div
                    *ngIf="submitted && Communities.websiteUrl.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="Communities.websiteUrl.errors.required">
                      Website is required
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Twitter</label>
                  <input
                    placeholder="Twitter"
                    type="text"
                    formControlName="twitterUrl"
                    id="twitter"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>Linked In</label>
                  <input
                    placeholder="Linked In"
                    type="text"
                    formControlName="linkedinUrl"
                    id="linkedin"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Contact Person</label>
                  <input
                    placeholder="Contact Person"
                    type="text"
                    formControlName="contactperson"
                    id="contactperson"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Email Id</label>
                  <input
                    placeholder="Email ID"
                    type="text"
                    formControlName="emailid"
                    id="emailid"
                    autocomplete="off"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && Communities.emailid.errors
                    }"
                  />
                  <div
                    *ngIf="submitted && Communities.emailid.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="Communities.emailid.errors.required">
                      Email id is required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Mobile Number</label>
                  <input
                    placeholder="Mobile Number"
                    type="text"
                    formControlName="mobilenumber"
                    id="mobilenumber"
                    autocomplete="off"
                    class="form-control"
                    [NumberOnly]="false"
                appNumbericOnly
                  />
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label  >Image Upload</label>
                      <div class="input-group">
                        <div class="custom-file">
                          <input type="file" (change)="communitylogo($event.target.files)" accept="image/x-png,image/gif,image/jpeg"
                           id="image">
                          <label for="image"
                            class="custom-file-label">Choose file</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <img  class="avatar-user avatar img"
                    src="{{user_avatar}}">
                  </div>
                </div>
              </div>
              <!-- /.card-body -->

              <div class="card-footer ">
                <span class="float-right">

                  <button
                    type="submit"
                    (click)="reset()"
                    class="btn btn-danger ml-2"
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    [disabled]="buttonEnable != true"
                    class="btn btn-success"
                  >
                    Submit
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- <app-az-footer></app-az-footer> -->
