import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiServicesService } from '../../../Services/api-services.service'
import { AlertService } from '../../common/Alert/alert/alert.services';
import { AppConfig } from '../../../appConfig/app.config';
import { Router } from '@angular/router';


@Component({
  selector: 'app-az-sync-speakers',
  templateUrl: './az-sync-speakers.component.html',
  styleUrls: ['./az-sync-speakers.component.scss']
})
export class AzSyncSpeakersComponent implements OnInit {
  ButtonName:any;
  submitted = false;
  public sidebarMinimized = false;
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  isVisibleLbl=false;
  SyncResult:string;
  syncmsg="Synchronization In-Process... It will take few minutes, Please hold on..."


  constructor(
    private apiCall: ApiServicesService,
    private router:Router,
    private alert:AlertService,
    private appConfig:AppConfig

    ) { }

    ngOnInit(): void {
    }

    SyncSpeakersData() {
      this.SyncResult=this.syncmsg
      this.isVisibleLbl=true;
      this.apiCall.SyncSessionize("speakers").subscribe(res => {
        debugger;
        if(res!=null ||res!=undefined){
          if(res==true)
          {
           this.SyncResult="Synchronization Completed"
          }
        }
        else{
          error=>{
             alert(error)
          }

        }
      })

    }
    SyncSessionsData() {
      this.SyncResult=this.syncmsg;
      this.isVisibleLbl=true;
      this.apiCall.SyncSessionize("session").subscribe(res => {
        debugger;
        if(res!=null ||res!=undefined){
          if(res==true)
          {
           this.SyncResult="Synchronization Completed"
          }
        }
        else{
          error=>{
             alert(error)
          }

        }
      })

    }
}
