import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ApiServicesService } from "../../Services/api-services.service";
import { AlertService } from "../common/Alert/alert/alert.services";
import { AppConfig } from "../../appConfig/app.config";
import { Router } from "@angular/router";

@Component({
  selector: "app-sessionize",
  templateUrl: "./sessionize.component.html",
  styleUrls: ["./sessionize.component.scss"],
})
export class SessionizeComponent implements OnInit {
  SessionizeForm: UntypedFormGroup;
  ButtonName: any;
  submitted = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiCall: ApiServicesService,
    private router: Router,
    private alert: AlertService,
    private appConfig: AppConfig
  ) {}

  ngOnInit(): void {
    this.ButtonName = "Save";
    this.SessionizeForm = this.formBuilder.group({
      SessionizeSpeakerURL: new UntypedFormControl("", Validators.required),
      SessionizeSessionURL: new UntypedFormControl("", Validators.required),
      EventYear: new UntypedFormControl(this.appConfig.GetEventYear),
    });
    this.GetSessionize();
  }

  get Sessionize() {
    return this.SessionizeForm.controls;
  }

  GetSessionize() {
    this.apiCall.GetSessionize().subscribe((res) => {
      if (res != null || res != undefined) {
        this.SessionizeForm.get("SessionizeSpeakerURL").setValue(
          res.sessionizeSpeakerURL
        );
        this.SessionizeForm.get("SessionizeSessionURL").setValue(
          res.sessionizeSessionURL
        );
        this.ButtonName = "Edit";
        this.SessionizeForm.controls["SessionizeSpeakerURL"].disable();
        this.SessionizeForm.controls["SessionizeSessionURL"].disable();
      } else {
        (error) => {
          alert(error);
        };
      }
    });
  }
  addSessionize() {
    if (this.ButtonName === "Edit") {
      this.SessionizeForm.controls["SessionizeSpeakerURL"].enable();
      this.SessionizeForm.controls["SessionizeSessionURL"].enable();
      this.ButtonName = "Update";
      return;
    }
    if (this.SessionizeForm.invalid) {
      return;
    }
    debugger;
    this.apiCall.AddSessionize(this.SessionizeForm.value).subscribe((res) => {
      debugger;
      if (res != null || res != undefined) {
        if (res == true) {
          this.SessionizeForm.get("SessionizeSpeakerURL").setValue(
            res.sessionizeSpeakerURL
          );
          this.SessionizeForm.get("SessionizeSessionURL").setValue(
            res.sessionizeSessionURL
          );
          this.SessionizeForm.controls["SessionizeSpeakerURL"].disable();
          this.SessionizeForm.controls["SessionizeSessionURL"].disable();
          this.ButtonName = "Edit";
        }
      } else {
        (error) => {
          alert(error);
        };
      }
    });
  }
  reset() {}
}
