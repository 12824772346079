<nav class="main-header navbar navbar-expand navbar-white navbar-light">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" role="button"
        ><i class="fas fa-bars"></i
      ></a>
    </li>
    <!-- <li class="nav-item d-none d-sm-inline-block">
      <a class="nav-link">Home</a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <a class="nav-link">Contact</a>
    </li> -->
  </ul>
  <ul class="navbar-nav ml-auto">
    <li class="nav-item">
      <a class="nav-link" data-widget="navbar-search" role="button">
        <i class="fas fa-search"></i>
      </a>
      <div class="navbar-search-block">
        <form class="form-inline">
          <div class="input-group input-group-sm">
            <input
              class="form-control form-control-navbar"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <div class="input-group-append">
              <button class="btn btn-navbar" type="submit">
                <i class="fas fa-search"></i>
              </button>
              <button
                class="btn btn-navbar"
                type="button"
                data-widget="navbar-search"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </li>

    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown">
        <i class="fas fa-user"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <a class="dropdown-item">
          <div class="media">
            <img
              src="../../../../assets/img/user2-160x160.jpg"
              alt="User Avatar"
              class="img-size-50 mr-3 img-circle"
            />
            <div class="media-body mt-3">
              <a (click)="userLogout()">
              <h3 class="dropdown-item-title">

                  LogOut
                </h3>
                </a>




            </div>
          </div>
          <!-- Message End -->
        </a>
        <div class="dropdown-divider"></div>
      </div>
    </li>

    <li class="nav-item">
      <a class="nav-link" data-widget="fullscreen" role="button">
        <i class="fas fa-expand-arrows-alt"></i>
      </a>
    </li>
  </ul>
</nav>
