import { Component, OnInit } from "@angular/core";

import { ApiServicesService } from "../../Services/api-services.service";
import {
  UntypedFormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { AlertService } from "../../views/common/Alert/alert/alert.services";
import { AppConfig } from "../../appConfig/app.config";
@Component({
  selector: "app-editworkshop",
  templateUrl: "./editworkshop.component.html",
  styleUrls: ["./editworkshop.component.scss"],
})
export class EditworkshopComponent implements OnInit {
  editWorkshopForm: UntypedFormGroup;
  submitted = false;
  workshopid: string;
  // public sidebarMinimized = false;

  public Editor = ClassicEditor;
  getById: any;
  // ckconfig = {
  //   toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
  // };

  constructor(
    private apicall: ApiServicesService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alert: AlertService,
    private apiconfig: AppConfig
  ) {}

  ngOnInit() {
    this.workshopid = this.route.snapshot.paramMap.get("workshopid");
    if (
      this.workshopid != null ||
      this.workshopid != "" ||
      this.workshopid != undefined
    ) {
      this.getWorkShopdetail(this.workshopid);
    } else {
      this.router.navigate(["/workshoplist"]);
    }
    this.editWorkshopForm = this.formBuilder.group({
      id: new UntypedFormControl(""),
      slugURL: new UntypedFormControl("", Validators.required),
      registerURL: new UntypedFormControl(""),
      fees: new UntypedFormControl(0),
      description: new UntypedFormControl(""),
      totalHours: new UntypedFormControl("", Validators.required),
      maxAttendees: new UntypedFormControl(""),
      workshopCategory: new UntypedFormControl("", Validators.required),
      isPaid: new UntypedFormControl(false, Validators.required),
      createdBy: new UntypedFormControl(localStorage.getItem("UserId")),
      sessionId: new UntypedFormControl(this.workshopid),
      isRegOpen: new UntypedFormControl(false),
      eventyear: new UntypedFormControl(this.apiconfig.GetEventYear),
    });
  }
  get editworkshop() {
    return this.editWorkshopForm.controls;
  }

  getWorkShopdetail(id) {
    //apiCall
    this.apicall.getWorkShopdetails(id).subscribe((dat) => {
      this.getById = dat;
      debugger;
      if (dat == null || dat == undefined) {
        this.apicall.shareddata.subscribe((data) => {
          for (let assgin of data) {
            this.editWorkshopForm.get("slugURL").setValue(assgin.title);
          }
        });
      } else {
        //assginValue
        this.editWorkshopForm.patchValue({
          id: this.getById.id,
          slugURL: this.getById.title,
          registerURL: this.getById.registerURL,
          fees: this.getById.fees,
          description: this.getById.description,
          isRegOpen: this.getById.isRegOpen,
          totalHours: this.getById.totalHours,
          isPaid: this.getById.isPaid,
          workshopCategory: this.getById.workshopCategory,
          maxAttendees: this.getById.maxAttendees,
        });
      }
    });
  }

  updateWorkshop() {
    if (this.editWorkshopForm.invalid) {
      return true;
    }
    this.editWorkshopForm
      .get("fees")
      .setValue(Number(this.editWorkshopForm.get("fees").value));
    this.apicall.addWorkShop(this.editWorkshopForm.value).subscribe((res) => {
      if (res == true) {
        this.alert.success("Updated Success");
        this.router.navigate(["/workshoplist"]);
      } else {
        (error) => {
          this.alert.error(error);
        };
      }
      console.log(res);
    });
  }
}
