import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-az-add-speakers',
  templateUrl: './az-add-speakers.component.html',
  styleUrls: ['./az-add-speakers.component.scss']
})
export class AzAddSpeakersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
