<app-az-header>
</app-az-header>


  <app-az-menu></app-az-menu>
  <app-loader [Loader]="IsLoading"></app-loader>
    <!-- Main content -->

    <div class="content-wrapper">
      <section class="content">

          <div class="container-fluid">
            <div class="animated fadeIn">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-header">
                        <i class="fa fa-align-justify"></i> Sponsors List
                      </div>
                      <div class="card-body">


                        <div class="col-md-3 search">
                          <input type="text"class="form-control search-box" (keyup)="sponsorsFilter($event)" placeholder="Search">
                          </div>


                          <table class="table" mat-table [dataSource]="dataSource">
                            <ng-container matColumnDef="S.No">
                              <th *matHeaderCellDef>S.No</th>
                              <td  *matCellDef="let data let i = index "> {{paginator.pageIndex * paginator.pageSize+i+1}}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="Community Name">
                              <th   *matHeaderCellDef> Community Name </th>
                              <td  *matCellDef="let data  "> {{data.name?data.name:"N/A"}}</td>
                            </ng-container>
                            <ng-container matColumnDef="Type">
                              <th  *matHeaderCellDef> Type </th>
                              <td  *matCellDef="let data"> <span *ngIf="data.sponsorType===1">Platinum</span>
                                <span *ngIf="data.sponsorType===2">Gold</span>
                                <span *ngIf="data.sponsorType===3">Community</span>
                                <span *ngIf="data.sponsorType===4">Learning</span>
                                <span *ngIf="data.sponsorType===5">Media</span>
                                <span *ngIf="data.sponsorType===6">Security</span>
                                <span *ngIf="data.sponsorType===7">Hackathon</span>
                                <span *ngIf="data.sponsorType===8">Digital Badge</span>
                                <span *ngIf="data.sponsorType===9">Silver</span>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="Website">
                              <th  *matHeaderCellDef> Website </th>
                              <td  *matCellDef="let data"> <a target="_blank" href="{{data.websiteUrl?data.websiteUrl:'N/A'}}">{{data.websiteUrl?'Visit':"N/A"}}</a> </td>
                            </ng-container>

                            <ng-container matColumnDef="Twitter">
                              <th  *matHeaderCellDef> Twitter </th>
                              <td  *matCellDef="let data"> <a target="_blank" href="{{data.twitterUrl?data.twitterUrl:'N/A'}}">{{data.twitterUrl?'Profile':"N/A"}} </a></td>
                            </ng-container>
                            <ng-container matColumnDef="LinkedIn">
                              <th  *matHeaderCellDef> LinkedIn </th>
                              <td  *matCellDef="let data"><a target="_blank" href="{{data.linkedInUrl?data.linkedInUrl:'N/A'}} ">{{data.linkedInUrl?'Profile':"N/A"}}</a> </td>
                            </ng-container>


                            <ng-container matColumnDef="Logo">
                              <th  *matHeaderCellDef> Logo </th>
                              <td  *matCellDef="let data"><img src="{{data.logoPath?data.logoPath:'assets/img/avatars/6.jpg'}}" class="img-avatar img-class" alt="{{data.name}}"/> </td>
                            </ng-container>

                            <ng-container matColumnDef="Action">
                              <th  *matHeaderCellDef> Action </th>
                              <td  *matCellDef="let data"> <mat-slide-toggle [name]="i"  [(ngModel)]="data.isActive"  (change)="changeStatus(data)"  ></mat-slide-toggle> </td>
                            </ng-container>

                            <ng-container matColumnDef="Edit">
                              <th  *matHeaderCellDef> Edit </th>
                              <td  *matCellDef="let data"> <i  class="fas fa-edit"  [routerLink]="['/sponseredit',data.id]"></i> </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                          </table>
          <mat-paginator class="pagnacolor" [pageSizeOptions]="pageSizes" showFirstLastButtons></mat-paginator>

                      </div>
                    </div>
                  </div>

                </div>

              </div>

          </div>

    </section>
      </div>






