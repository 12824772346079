import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-az-menu',
  templateUrl: './az-menu.component.html',
  styleUrls: ['./az-menu.component.scss']
})
export class AzMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
