<app-az-header> </app-az-header>
<app-alert></app-alert>

<app-az-menu></app-az-menu>
<app-loader [Loader]="IsLoading"></app-loader>
<!-- Main content -->

<div class="content-wrapper">
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Create Coupon</h3>
            </div>
            <form [formGroup]="pricingForm" (ngSubmit)="addpricing()">
              <div class="card-body">
                <div class="form-group">
                  <label>Plan Type</label>
                  <input
                    type="text"
                    formControlName="type"
                    id="Plantype"
                    [ngClass]="{
                      'is-invalid': submitted && pricing.type.errors
                    }"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Plan Type"
                  />
                  <span
                    *ngIf="submitted && pricing.type.errors"
                    class="invalid-feedback"
                  >
                    <span *ngIf="pricing.type.errors.required">
                      Plan Type is required
                    </span>
                  </span>
                </div>

                <div class="form-group">
                  <label>Price</label>
                  <input
                    type="text"
                    formControlName="price"
                    id="pricing"
                    [ngClass]="{
                      'is-invalid': submitted && pricing.price.errors
                    }"
                    maxlength="6"
                    [NumberOnly]="false"
                    appNumbericOnly
                    autocomplete="off"
                    class="form-control"
                    placeholder="Pricing"
                  />
                  <div
                    *ngIf="submitted && pricing.price.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="pricing.price.errors.required">
                      Price is required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Discount</label>
                  <input
                    type="text"
                    formControlName="discountPercentage"
                    id="pricing"
                    maxlength="6"
                    [NumberOnly]="false"
                    appNumbericOnly
                    autocomplete="off"
                    class="form-control"
                    placeholder="Discount Percentage "
                  />
                </div>

                <div class="form-group">
                  <label>Ticket Count</label>
                  <input
                  [ngClass]="{
                    'is-invalid': submitted && pricing.ticketCount.errors
                  }"
                    type="text"
                    formControlName="ticketCount"
                    id="count"
                    maxlength="6"
                    [NumberOnly]="false"
                    appNumbericOnly
                    autocomplete="off"
                    class="form-control"
                    placeholder="Ticket Count"
                  />

                  <div
                    *ngIf="submitted && pricing.ticketCount.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="pricing.ticketCount.errors.required">
                      Ticket Count is required
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Last Date</label>
                  <mat-form-field appearance="fill">
                    <mat-label>Choose a last date</mat-label>
                    <input
                      [min]="minDate"
                      formControlName="lastDate"
                      matInput
                      [matDatepicker]="picker"
                    />
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>

                <div
                  formArrayName="benifits"
                  *ngFor="
                    let item of getformgroup();
                    let i = index;
                    let first = first;
                    let last = last
                  "
                >
                  <div class="row" [formGroupName]="i">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Benifits</label>
                        <input
                          type="search"
                          formControlName="ticketbenfit"
                          id="benefits"
                          autocomplete="off"
                          class="form-control"
                          placeholder="Benefits"
                          [ngClass]="{
                            'is-invalid':
                              submitted &&
                              item.get('ticketbenfit')?.errors?.required
                          }"
                          autocomplete="off"
                        />

                        <div
                          *ngIf="item.get('ticketbenfit')?.errors?.required"
                          class="invalid-feedback"
                        >
                          Benifits is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <span>
                        <button
                          class="add mt-4"
                          type="button"
                          (click)="addControl(i)"
                          *ngIf="last"
                          mat-fab
                          color="primary"
                          aria-label="Example icon button with a delete icon"
                        >
                          <span><i class="fas fa-plus"></i></span>
                          <!-- <mat-icon>+</mat-icon> -->
                        </button>

                        <button
                          class="remove mt-4"
                          type="button"
                          (click)="removeControl(i)"
                          *ngIf="!(first && last)"
                          mat-fab
                          color="primary"
                          aria-label="Example icon button with a delete icon"
                        >
                          <span><i class="fa fa-times"></i></span>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- /.card-body -->

              <div class="card-footer">
                <span class="float-right">
                  <button type="submit" class="btn btn-success">Save</button>
                  <button
                    type="submit"
                    (click)="reset()"
                    class="btn btn-danger ml-2"
                  >
                    Reset
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- <app-az-footer></app-az-footer> -->
