import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ApiServicesService } from "../../../Services/api-services.service";
 


@Component({
  selector: "app-counponlist",
  templateUrl: "./couponlist.component.html",
  styleUrls: ["./counponlist.component.scss"],
})
export class CounponlistComponent implements OnInit {
  pageSizes = [5, 10, 20, 50];
  displayedColumns: string[] = [
    "S.No",
    "Coupon Code",
    "Percentage",
    "CouponCount",
    "Action",
    "Edit",
  ];
  dataSource = new MatTableDataSource();
  couponList = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  IsLoading=false;

  editField: any;

  constructor(private apiCall: ApiServicesService) {}

  ngOnInit(): void {
    this.getCouponList();
  }

  getCouponList(couponCode?) {
    this.IsLoading=true;
    this.apiCall.getCoupon().subscribe((coupon) => {
      console.log(coupon);
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = coupon;
      this.dataSource.paginator = this.paginator;
      this.couponList = coupon;
      this.IsLoading=false;
    });
  }
  couponFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateCoupon(data) {
    this.editField = data;
    console.log(data);
  }

  changeStatus(coupon) {
    this.apiCall
      .statusChangeCoupon(coupon.couponId, coupon.status)
      .subscribe((resdata) => {
        if (resdata) {
          //this.getCouponList()
        }
      });
  }
  updateCoupons(data) {
    this.IsLoading=true;
    data.percentage=Number(data.percentage)
    data.couponCount=Number(data.couponCount)

    this.apiCall.couponUpdate(data).subscribe((res) => {
      if(res){
        this.IsLoading=false;
        this.getCouponList()
      }
       this.IsLoading=false;
    });


  }
  cancel(){
    this.editField=''

  }
}
