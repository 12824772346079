<app-az-header> </app-az-header>
<app-az-menu> </app-az-menu>
<app-loader [Loader]="IsLoading"></app-loader>

<div class="content-wrapper">
  <section class="content">
    <div class="container-fluid">
      <div class="animated fadeIn">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <i class="fa fa-align-justify"></i> Faq List
              </div>
              <div class="card-body">
                <div class="col-md-3 search">
                  <input
                    type="text"
                    class="form-control search-box"
                    (keyup)="FaqFilter($event)"
                    placeholder="Search"
                  />
                </div>

                <table class="table" mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="S.No">
                    <th *matHeaderCellDef>S.No</th>
                    <td *matCellDef="let data; let i = index">
                      {{ paginator.pageIndex * paginator.pageSize + i + 1 }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Faq Question">
                    <th *matHeaderCellDef>Faq Question</th>
                    <td *matCellDef="let data">
                      {{ data.faqQuestion ? data.faqQuestion : "N/A" }}
                      {{ data.lastName }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Faq Answer">
                    <th *matHeaderCellDef>Faq Answer</th>
                    <td *matCellDef="let data">
                      <div
                        [innerHtml]="data.faqAnswer ? data.faqAnswer : 'N/A'"
                      ></div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Edit">
                    <th *matHeaderCellDef>Edit</th>
                    <td *matCellDef="let data">
                      <i
                        class="fa fa-edit"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        (click)="updateFaq(data)"
                      ></i>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
                <mat-paginator
                  class="pagnacolor"
                  [pageSizeOptions]="pageSizes"
                  showFirstLastButtons
                ></mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<div
  *ngIf="isEdit"
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form [formGroup]="editFaq" (ngSubmit)="faqUpdate()">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Price Update</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card-body">
            <div class="col-md-12">
              <div class="form-group">
                <label>Faq Type</label>
                <select class="form-control" formControlName="faqType">
                  <option disabled>Select Type</option>
                  <option value="General">General</option>
                  <option value="Register">Register</option>
                  <option value="Cloud">Cloud</option>
                </select>

                <span
                  *ngIf="editFaq.get('faqType')?.errors?.required && submitted"
                  class="is-invalid"
                  >FaqType is required</span
                >
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Faq Question</label>

                <textarea
                  class="form-control"
                  placeholder="Faq Question"
                  formControlName="faqQuestion"
                >
                </textarea>

                <span
                  *ngIf="
                    editFaq.get('faqQuestion')?.errors?.required && submitted
                  "
                  class="is-invalid"
                  >Faq question is required</span
                >
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Faq Question</label>
                <ckeditor
                  [editor]="Editor"
                  formControlName="faqAnswer"
                  id="Faqanswer"
                ></ckeditor>

                <span
                  *ngIf="
                    editFaq.get('faqAnswer')?.errors?.required && submitted
                  "
                  class="is-invalid"
                  >Faq answer is required</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button
            type="button"
            class="btn btn-danger"
            #close
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button type="submit" class="btn btn-success">Update</button>
        </div>
      </form>
    </div>
  </div>
</div>
