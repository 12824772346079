import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
 
import { Router } from "@angular/router";
import { PaymentService } from "../../../Services/paymentService.service ";
import { ApiServicesService } from "../../../Services/api-services.service";
 

@Component({
  selector: "app-createbulkticket",
  templateUrl: "./createbulkticket.component.html",
  styleUrls: ["./createbulkticket.component.scss"],
})
export class CreateBulkTicket implements OnInit {
  IsLoading = false;
  submitted = false;
  bulkCreateTicketForm: FormGroup;
  returnPaymentStatus: any;
  totalAmount: number;
  includeGst: number;
  isCoupon: boolean = false;
  discountAmount: number;
  gstAmount: number;
  excludeGst: number;
  ticketAmount: any;
  totalCouponAmount: number;
  priceType: any;
  constructor(
    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private apiService: ApiServicesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.Init();
    this.getPriceType();
  }
  Init() {
    this.bulkCreateTicketForm = this.formBuilder.group({
      totalticket: new FormControl("", Validators.required),
      ticketAmount: new FormControl("", Validators.required),
      companyName: new FormControl("", Validators.required),
      priceType: new FormControl("", Validators.required),
      gstNumber: new FormControl(
        "",
        Validators.pattern(
          "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$"
        )
      ),
      address: new FormControl("", Validators.required),
      couponCode: new FormControl(""),
      emailId: new FormControl("", [
        Validators.required,
        Validators.pattern(
          "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$"
        ),
      ]),
      paymentMethod: new FormControl("", Validators.required),
    });
  }

  get bulkTicketForm() {
    return this.bulkCreateTicketForm.controls;
  }

  checkGst(percentage?) {
    if (this.bulkCreateTicketForm.get("ticketAmount")?.value) {
      this.ticketAmount = this.excludeGst =
        this.bulkCreateTicketForm.get("totalticket")?.value *
        this.bulkCreateTicketForm.get("ticketAmount")?.value;
      this.gstAmount = Math.round((this.ticketAmount * 18) / 100);
      this.totalAmount = this.excludeGst + this.gstAmount;
    }
  }

  CreateTicket() {
    this.submitted = true;
    if (!this.bulkCreateTicketForm.valid) {
      return false;
    } else {
      let paymentModel: PaymentModel = {
        BulkTicketCount: Number(
          this.bulkCreateTicketForm.get("totalticket")?.value
        ),
        Price: Number(this.bulkCreateTicketForm.get("ticketAmount")?.value),
        TotalPrice: this.ticketAmount,
        TotalAmount: this.totalAmount,
        GSTAmount: this.gstAmount,
        CompanyName: this.bulkCreateTicketForm.get("companyName")?.value,
        GSTNumber: this.bulkCreateTicketForm.get("gstNumber")?.value,
        Address: this.bulkCreateTicketForm.get("address")?.value,
        PriceType: this.bulkCreateTicketForm.get("priceType")?.value,
        EmailId: this.bulkCreateTicketForm.get("emailId")?.value,
        couponCode: this.bulkCreateTicketForm.get("couponCode")?.value,
      };

     const paymentMethod =this.bulkCreateTicketForm.get("paymentMethod")?.value
  if(paymentMethod !== "Razorpay"){
    this.IsLoading = true;
      this.paymentService.createPhonePeBulkTicket(paymentModel).subscribe((res) => {
        if (res != null) {
          this.IsLoading = false;
          this.router.navigateByUrl("bulkpaymentlist");
        }
        this.IsLoading = false;
      });
    }
    else{
      this.paymentService.createRazoryPayBulkTicket(paymentModel).subscribe((res) => {
        if (res != null) {
          this.IsLoading = true;
          this.router.navigateByUrl("bulkpaymentlist");
        }
        this.IsLoading = false;
      });
    }
  }
  }

  getPriceType() {
    this.apiService.getPrice("all", true).subscribe((res) => {
      this.priceType = res;
    });
  }
}

interface PaymentModel {
  BulkTicketCount: number;
  Price: number;
  TotalPrice: number;
  TotalAmount: number;
  GSTAmount: number;
  CompanyName: string;
  GSTNumber: string;
  Address: string;
  PriceType: string;
  EmailId: string;
  couponCode: string;
}
