import { Component, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ApiServicesService } from "../../../Services/api-services.service";

import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
@Component({
  selector: "app-addfaq",
  templateUrl: "./addfaq.component.html",
  styleUrls: ["./addfaq.component.scss"],
})
export class AddfaqComponent implements OnInit {
  submitted = false;
  faqForm: UntypedFormGroup;

  public Editor = ClassicEditor;
  IsLoading=false
  constructor(
    private builder: UntypedFormBuilder,
    private apiService: ApiServicesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.faqForm = this.builder.group({
      faq: this.builder.array([this.faq()]),
      faqType: new UntypedFormControl("", Validators.required),
    });
  }

  faq(): UntypedFormGroup {
    return this.builder.group({
      faqQuestion: new UntypedFormControl("", Validators.required),
      faqAnswer: new UntypedFormControl("", Validators.required),
    });
  }

  getFaqForm() {
    return (this.faqForm.get("faq") as UntypedFormArray).controls;
  }

  addFaq(i: any) {
    debugger;
    if (this.faqForm.get("faq")?.valid) {
      const productnames = this.faqForm.get("faq") as UntypedFormArray;
      productnames.push(this.faq());
    } else {
      this.submitted = true;
      this.faqForm.get("faq")?.markAllAsTouched();
    }
  }
  faqDelete(i: any) {
    const removeQuetion = this.faqForm.get("faq") as UntypedFormArray;
    removeQuetion.removeAt(i);
  }
  get getFaq() {
    return this.faqForm.controls;
  }
  saveFaq() {
    this.submitted = true;
    if (this.faqForm.invalid) {
      console.log(this.faqForm.invalid);
      this.faqForm.markAllAsTouched();
      return;
    }
    this.IsLoading=true

    this.apiService.createFaq(this.faqForm.value).subscribe((fq) => {
      console.log(fq);
      if (fq) {
        this.IsLoading=false
        this.router.navigate(["/listfaq"]);
      }
    });
  }

  reset() {
    this.faqForm.reset();
  }
}
