<app-az-header> </app-az-header>
<app-alert></app-alert>

<app-az-menu></app-az-menu>
<app-loader [Loader]="IsLoading"></app-loader>
<!-- Main content -->

<div class="content-wrapper">
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Add Sponsors</h3>
            </div>
            <form [formGroup]="couponForm" (ngSubmit)="createCoupon()">
              <div class="card-body">
                <div class="form-group">
                  <label>Coupon Code</label>
                  <input
                    type="text"
                    formControlName="couponCode"
                    id="coupon Code"
                    [ngClass]="{
                      'is-invalid': submitted && coupon.couponCode.errors
                    }"
                    autocomplete="off"
                    class="form-control"
                    placeholder="coupon Code"
                  />
                  <div
                    *ngIf="submitted && coupon.couponCode.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="coupon.couponCode.errors.required">
                      Coupon name is required
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Perentage</label>
                  <input
                    type="text"
                    formControlName="percentage"
                    id="about"
                    [ngClass]="{
                      'is-invalid': submitted && coupon.percentage.errors
                    }"
                    [NumberOnlyWithDot]="false"
                    appNumbericOnly
                    autocomplete="off"
                    class="form-control"
                    placeholder="Percentage"
                  />
                  <div
                    *ngIf="submitted && coupon.percentage.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="coupon.percentage.errors.required">
                      Percentage is required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Coupon Count</label>
                  <input
                    type="text"
                    formControlName="couponCount"
                    id="couponCount"
                    [NumberOnly]="false"
                    appNumbericOnly
                    autocomplete="off"
                    class="form-control"
                    placeholder="Coupon Count"
                  />
                </div>
              </div>

              <!-- /.card-body -->
              <span class="invalid ml-4">{{ messgae }}</span>
              <div class="card-footer">
                <span class="float-right">
                  <button
                    type="submit"
                    (click)="reset()"
                    class="btn btn-danger ml-2"
                  >
                    Reset
                  </button>
                  <button type="submit" class="btn btn-success">Save</button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- <app-az-footer></app-az-footer> -->
