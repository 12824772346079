<app-az-header> </app-az-header>
<app-az-menu> </app-az-menu>
<app-loader [Loader]="IsLoading"></app-loader>
<div class="content-wrapper">
  <section class="content">
    <div class="container-fluid">
      <div class="animated fadeIn">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <i class="fa fa-align-justify"></i> Price Details
              </div>
              <div class="card-body">
                <div class="col-md-3 search">
                  <input
                    type="text"
                    class="form-control search-box"
                    placeholder="Search"
                  />
                </div>

                <table class="table" mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="S.No">
                    <th *matHeaderCellDef>S.No</th>
                    <td *matCellDef="let data; let i = index">
                      {{ paginator.pageIndex * paginator.pageSize + i + 1 }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Price Type">
                    <th *matHeaderCellDef>Price Type</th>
                    <td *matCellDef="let data">
                      <span *ngIf="editField != data">
                        {{ data.type ? data.type : "N/A" }}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Price">
                    <th *matHeaderCellDef>Price</th>
                    <td *matCellDef="let data">
                      <span *ngIf="editField != data">
                        {{
                          data.price ? (data.price | currency : "INR") : "N/A"
                        }}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Discount">
                    <th *matHeaderCellDef>Discount</th>
                    <td *matCellDef="let data">
                      <span *ngIf="editField != data">
                        {{
                          data.discountPercentage
                            ? (data.discountPercentage / 100 | percent)
                            : "N/A"
                        }}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Benifits">
                    <th *matHeaderCellDef>Benifits</th>
                    <td *matCellDef="let data">
                      <span class="truncate">
                        {{ data.benefits }}
                        <!-- <span *ngIf="!(last)">,</span> -->
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Last Date">
                    <th *matHeaderCellDef>Last Date</th>
                    <td *matCellDef="let data">
                      <span>
                        {{
                          data.lastDate
                            ? (data.lastDate | date : "dd MMM yyyy")
                            : "N/A"
                        }}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Total Ticket">
                    <th *matHeaderCellDef>Total Ticket</th>
                    <td *matCellDef="let data">
                      <span>
                        {{ data.totalTicketCount ? data.totalTicketCount : "N/A" }}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Remaining Ticket">
                    <th *matHeaderCellDef>Remaining Ticket</th>
                    <td *matCellDef="let data">
                      <span>
                        {{
                          data.remainingTicketCount ? data.remainingTicketCount : "N/A"
                        }}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Action">
                    <th *matHeaderCellDef>Action</th>
                    <td *matCellDef="let data">
                      <mat-slide-toggle
                        [name]="i"
                        [(ngModel)]="data.status"
                        (change)="changeStatus(data)"
                      ></mat-slide-toggle>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Edit">
                    <th *matHeaderCellDef>Edit</th>
                    <td *matCellDef="let data">
                      <i
                        class="fa fa-edit"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        (click)="updatePrice(data)"
                      ></i>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
                <mat-paginator
                  class="pagnacolor"
                  [pageSizeOptions]="pageSizes"
                  showFirstLastButtons
                ></mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div
  *ngIf="isEdit"
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form [formGroup]="editForm" (ngSubmit)="update()">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Price Update</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card-body">
            <div class="col-md-12">
              <div class="form-group">
                <label>Package Type</label>
                <input
                  class="form-control"
                  placeholder="Package Name"
                  formControlName="type"
                />
                <span
                  *ngIf="editForm.get('type')?.errors?.required && submitted"
                  class="is-invalid"
                  >Type is required</span
                >
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Price</label>
                <input
                  class="form-control"
                  placeholder="Price"
                  formControlName="price"
                  [NumberOnly]="false"
                  appNumbericOnly
                />

                <span
                  *ngIf="editForm.get('price')?.errors?.required && submitted"
                  class="is-invalid"
                  >price is required</span
                >
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Discount</label>
                <input
                  class="form-control"
                  placeholder="Discount Percentage"
                  formControlName="discountPercentage"
                  [NumberOnly]="false"
                  appNumbericOnly
                />
              </div>
            </div>
            <div class="form-group">
              <label>Ticket Count</label>
              <input
                type="text"
                formControlName="totalTicketCount"
                id="count"
                maxlength="6"
                [NumberOnly]="false"
                appNumbericOnly
                autocomplete="off"
                class="form-control"
                placeholder="Ticket Count"
              />
              <span
                *ngIf="
                  editForm.get('totalTicketCount')?.errors?.required && submitted
                "
                class="is-invalid"
                >Ticket Count is required</span
              >
            </div>
            <div class="form-group">
              <label>Remaining Ticket</label>
              <input
                type="text"
                formControlName="remainingTicketCount"
                id="count"
                maxlength="6"
                [NumberOnly]="false"
                appNumbericOnly
                autocomplete="off"
                class="form-control"
                placeholder="Ticket Count"
              />
              <span
                *ngIf="
                  editForm.get('remainingTicketCount')?.errors?.required && submitted
                "
                class="is-invalid"
                >Remaining Ticket Count is required</span
              >
            </div>


            <div class="col-md-12">
              <div class="form-group">
                <label>Benifits</label>
                <textarea
                  class="form-control"
                  placeholder="Benifits"
                  formControlName="benefits"
                >
                </textarea>

                <span
                  *ngIf="
                    editForm.get('benefits')?.errors?.required && submitted
                  "
                  class="is-invalid"
                  >Benifits is required</span
                >
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <mat-form-field appearance="fill">
                  <mat-label>Choose a last date</mat-label>
                  <input
                    [min]="minDate"
                    formControlName="lastDate"
                    matInput
                    class="form-control"
                    [matDatepicker]="picker"
                  />
                  <mat-hint>MM/DD/YYYY</mat-hint>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <span
                  *ngIf="
                    editForm.get('lastDate')?.errors?.required && submitted
                  "
                  class="is-invalid"
                  >Lastdate is required</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button
            type="button"
            class="btn btn-danger"
            #close
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button type="submit" class="btn btn-success">Update</button>
        </div>
      </form>
    </div>
  </div>
</div>
