<app-az-header> </app-az-header>
<app-alert></app-alert>

<app-az-menu></app-az-menu>
<div class="content-wrapper">
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Sessionize Data Sync</h3>
            </div>

              <div class="card-body text-center">
                <button class="btn btn-sm btn-primary" (click)="SyncSpeakersData()" style=" height: 43px; width: 150px; font-size: 16px; ">Speakers Sync</button>
                <button class="btn btn-sm btn-primary" (click)="SyncSessionsData()" style=" background-color: darkmagenta; height: 43px; width: 150px; font-size: 16px; ">Sessions Sync</button>
              </div>
              <!-- /.card-body -->
              <div *ngIf="isVisibleLbl" id="LblSync" style=" text-align: center; padding-top: 5%; ">
                <strong style="font-style: italic;">{{SyncResult}}</strong>
                </div>


          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- <app-az-footer></app-az-footer> -->
