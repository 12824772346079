<app-az-header>
</app-az-header>


  <app-az-menu></app-az-menu>

    <!-- Main content -->

    <div class="content-wrapper">
      <section class="content">

          <div class="container-fluid">
            <div class="animated fadeIn">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-header">
                        <i class="fa fa-align-justify"></i> Speakers List
                      </div>
                      <div class="card-body">


                        <div class="col-md-3 search">
                          <input type="text"class="form-control search-box" (keyup)="speakersFilter($event)" placeholder="Search">
                          </div>


                          <table class="table" mat-table [dataSource]="dataSource">
                            <ng-container matColumnDef="S.No">
                              <th *matHeaderCellDef>S.No</th>
                              <td  *matCellDef="let data let i = index "> {{paginator.pageIndex * paginator.pageSize+i+1}}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="Name">
                              <th   *matHeaderCellDef> Name </th>
                              <td  *matCellDef="let data  "> {{data.fullName?data.fullName:"N/A"}}</td>
                            </ng-container>
                            <ng-container matColumnDef="Designation">
                              <th  *matHeaderCellDef> Designation </th>
                              <td  *matCellDef="let data"> {{data.designation?data.designation:"N/A"}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Image">
                              <th  *matHeaderCellDef> Image </th>
                              <td  *matCellDef="let data"><img src="{{data.photoPath?data.photoPath:'assets/img/avatars/6.jpg'}}" class="img-avatar img-class" alt="{{data.name}}"/> </td>
                            </ng-container>
                            <ng-container matColumnDef="LinkedIn">
                              <th  *matHeaderCellDef> LinkedIn </th>
                              <td  *matCellDef="let data"> <a target="_blank" href="{{data.speakersSocialMedia[0]?data.speakersSocialMedia[0].url:'#'}}"> {{data.speakersSocialMedia[0]?'Bio':"#"}}</a> </td>
                            </ng-container>
                            <ng-container matColumnDef="Twitter">
                              <th   *matHeaderCellDef> Twitter </th>
                              <td  *matCellDef="let data"><a target="_blank" href="{{data.speakersSocialMedia[1]?data.speakersSocialMedia[1].url:'#'}}">{{data.speakersSocialMedia[1]?'Bio':"#"}}</a></td>
                            </ng-container>
                            <ng-container matColumnDef="Action">
                              <th  *matHeaderCellDef> Action </th>
                              <td  *matCellDef="let data"> <mat-slide-toggle [name]="i"  [(ngModel)]="data.isActive"  (change)="changeStatus(data)" ></mat-slide-toggle> </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                          </table>
          <mat-paginator class="pagnacolor" [pageSizeOptions]="pageSizes" showFirstLastButtons></mat-paginator>

                      </div>
                    </div>
                  </div>

                </div>

              </div>

          </div>

    </section>
      </div>






