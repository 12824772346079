import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ApiServicesService } from "../../../Services/api-services.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
@Component({
  selector: "app-list-faq",
  templateUrl: "./list-faq.component.html",
  styleUrls: ["./list-faq.component.scss"],
})
export class ListFaqComponent implements OnInit {
  isEdit = false;
  pageSizes = [5, 10, 20, 50];
  displayedColumns: string[] = ["S.No", "Faq Question", "Faq Answer", "Edit"];
  IsLoading = false;
  dataSource = new MatTableDataSource();
  editFaq: UntypedFormGroup;
  submitted = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("close") closeModel: ElementRef;
  public Editor = ClassicEditor;
  responseData: any;
  constructor(
    private apiCall: ApiServicesService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.getFaq();
    this.formInitiate();
  }

  formInitiate() {
    this.editFaq = this.formBuilder.group({
      faqType: new UntypedFormControl("", Validators.required),
      faqQuestion: new UntypedFormControl("", Validators.required),
      faqAnswer: new UntypedFormControl("", Validators.required),
      faqId: new UntypedFormControl(""),
    });
  }

  getFaq() {
    this.IsLoading = true;
    this.apiCall.getFaq().subscribe((faq) => {
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = faq;
      this.dataSource.paginator = this.paginator;
      this.IsLoading = false;
    });
  }

  FaqFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateFaq(data) {
    this.isEdit = true;

    this.editFaq.patchValue({
      faqType: data.faqType,
      faqQuestion: data.faqQuestion,
      faqAnswer: data.faqAnswer,
      faqId: data.faqId,
    });
  }

  faqUpdate() {
    if (this.editFaq.invalid) {
      this.submitted = true;
      return;
    } else {
      this.apiCall.FaqUpdate(this.editFaq.value).subscribe((fa) => {
        if (fa) {
          this.isEdit = false;
          this.closeModel.nativeElement.click();
          this.getFaq();
          this.IsLoading = false;
        }
        this.IsLoading = false;
      });
    }
  }
}
