import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot,Router, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthuguradServiceService} from './authugurad-service.service' 
@Injectable({
  providedIn: 'root'
})

export class AzconfdevGuard implements CanActivate {
 
constructor(

  private Authguardservice:AuthuguradServiceService  ,
  private router:Router
  )
  {
  
  }
    canActivate () :boolean
      
       {
  
        if(!this.Authguardservice.getUser())
        {
    debugger;
          this.router.navigateByUrl("Sigin");
    
        }
        return this.Authguardservice.getUser();
      } 
  }