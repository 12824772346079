import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { ApiServicesService } from '../../../Services/api-services.service';
import { AlertService } from '../../common/Alert/alert';
import { AppConfig } from '../../../appConfig/app.config';
 
@Component({
  selector: 'app-communities-add',
  templateUrl: './communities-add.component.html',
  styleUrls: ['./communities-add.component.scss']
})
export class CommunitiesAddComponent implements OnInit {
  CommunitiesForm: UntypedFormGroup;
  submitted=false
  buttonEnable=false
  IsLoading=false
  user_avatar='../../../../assets/img/logo.png'
  community:any
  // keys = Object.keys;
  // Communtity = Communtirytype;
  Communitytype=[{'id':1,'Name':'Azure'},{'id':2,'Name':'OpenSource'},{'id':3,'Name':'Diversity'}]
  communitieslogo:any
  options = {
    autoClose: true,
};


  constructor( private formBuilder: UntypedFormBuilder,
    private apiCall: ApiServicesService,
    private router:Router,
    private alert:AlertService,
    private appConfig:AppConfig

    ) { }

  ngOnInit(): void {

    this.CommunitiesForm = this.formBuilder.group({

      name: new UntypedFormControl('', Validators.required),
      about: new UntypedFormControl('',Validators.required),
      twitterUrl: new UntypedFormControl(''),
      linkedinUrl: new UntypedFormControl(''),
      logoPath: new UntypedFormControl(''),
      communityType:new UntypedFormControl(null,Validators.required),
      location:new UntypedFormControl(''),
      websiteUrl:new UntypedFormControl('',Validators.required),
      contactperson:new UntypedFormControl('',Validators.required),
      emailid:new UntypedFormControl('',Validators.required),
      mobilenumber:new UntypedFormControl('',Validators.required),
      isActive:new UntypedFormControl(true),
      createdBy:new UntypedFormControl(localStorage.getItem('UserId')),
      eventYear:new UntypedFormControl(this.appConfig.GetEventYear),
      createdDate:new UntypedFormControl(new Date())
    })
  }

  get Communities() { return this.CommunitiesForm.controls; }

  addCommunities() {

     this.submitted = true;

    if (this.CommunitiesForm.invalid) {
      return;
    }
    this.IsLoading=true
      // const Tointcon=Number(this.community)
    this.CommunitiesForm.get('communityType').setValue(Number(this.CommunitiesForm.get('communityType').value))
    this.apiCall.CommunitiesRegister(this.CommunitiesForm.value).subscribe(res => {
          if(res!=null ||res!=undefined){

        this.router.navigate(['/ListCommunities'])
        this.IsLoading=false
      }
      else{
        error=>{
           alert(error)
           this.IsLoading=false
        }

      }
    })



  }

  reset(){
           this.alert.warn(" am trying to make the black div (relative) above the second yellow one (absolute). The black", this.options)
  }
// delete(){
//   this.showTopCenter('warn', 'Failure Message', 'Invalid OTP');
// }
  // showTopCenter(level: string, info: string, message: string) {
  //   this.message.add({ severity: level, summary: info, detail: message });
  // }

  communitylogo(image:FileList) {
    const img = image[0].name.split('.').pop();
    if (img === 'png' || img === 'PNG' || img === 'jpg' || img === 'JPG' || img === 'jpeg' || img === 'gif' || img === 'psd' || img === 'bmp') {
      this.IsLoading=true
      for (let _files in image) {
        if (_files != "length" && _files != "item") {
          this.communitieslogo=image.item(Number(_files));
          const formData = new FormData();
          formData.append("photo",this.communitieslogo)
          formData.append("EventYear",this.appConfig.GetEventYear)
          this.apiCall.uploadCommunitiesImage(formData).subscribe(res => {

            if(res!=null ||res!=undefined){
           this.CommunitiesForm.get('logoPath').setValue(res);
           this.user_avatar= this.CommunitiesForm.get('logoPath').value
            this.buttonEnable=true
            this.IsLoading=true
            }
            this.IsLoading=false
          })
        }
      }

    }
    else {

    }

  }


}

// export enum Communtirytype {
//   Azure,
//   OpenSource,
//   Diversity
// }
