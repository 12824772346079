<app-az-header> </app-az-header>
<app-alert></app-alert>
<div class="content-wrapper">
  <section class="content">
    <div class="container-fluid">
      <div class="animated fadeIn">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <i class="fa fa-align-justify"></i> WorkShop List
              </div>
              <div class="card-body">
                <form
                  [formGroup]="editWorkshopForm"
                  (ngSubmit)="updateWorkshop()"
                  class="form-horizontal"
                >
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input"
                      >WorkShop Titles</label
                    >
                    <div class="col-md-7">
                      <input
                        type="text"
                        formControlName="slugURL"
                        id="slugURL"
                        [ngClass]="{
                          'is-invalid': submitted && editworkshop.slugURL.errors
                        }"
                        autocomplete="off"
                        class="form-control"
                        placeholder="WorkShop Titles"
                      />
                      <div
                        *ngIf="submitted && editworkshop.slugURL.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="editworkshop.slugURL.errors.required">
                          WorkShop Titles is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-md-3 col-form-label">Register Url</label>
                    <div class="col-md-7">
                      <input
                        type="text"
                        formControlName="registerURL"
                        id="registerURL"
                        [ngClass]="{
                          'is-invalid':
                            submitted && editworkshop.registerURL.errors
                        }"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Register Url"
                      />
                      <div
                        *ngIf="submitted && editworkshop.registerURL.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="editworkshop.registerURL.errors.required">
                          Register Url is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input"
                      >Total Hours</label
                    >
                    <div class="col-md-7">
                      <input
                        type="text"
                        formControlName="totalHours"
                        id="totalHours"
                        [ngClass]="{
                          'is-invalid':
                            submitted && editworkshop.totalHours.errors
                        }"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Total Hours"
                      />
                      <div
                        *ngIf="submitted && editworkshop.totalHours.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="editworkshop.totalHours.errors.required">
                          Total Hours is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input"
                      >Workshop Category</label
                    >
                    <div class="col-md-7">
                      <input
                        type="text"
                        formControlName="workshopCategory"
                        id="workshopCategory"
                        [ngClass]="{
                          'is-invalid':
                            submitted && editworkshop.workshopCategory.errors
                        }"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Workshop Category"
                      />
                      <div
                        *ngIf="
                          submitted && editworkshop.workshopCategory.errors
                        "
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="editworkshop.workshopCategory.errors.required"
                        >
                          Workshop Category is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input"
                      >Maximum Attendees</label
                    >
                    <div class="col-md-7">
                      <input
                        type="text"
                        formControlName="maxAttendees"
                        id="maxAttendees"
                        [ngClass]="{
                          'is-invalid':
                            submitted && editworkshop.maxAttendees.errors
                        }"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Maximum Attendees"
                      />
                      <div
                        *ngIf="submitted && editworkshop.maxAttendees.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="editworkshop.maxAttendees.errors.required">
                          Maximum Attendees is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label">Is Paid</label>
                    <div class="col-md-7">
                      <mat-slide-toggle
                        name="isPaid"
                        formControlName="isPaid"
                        [ngClass]="{
                          'is-invalid': submitted && editworkshop.isPaid.errors
                        }"
                      ></mat-slide-toggle>
                      <div
                        *ngIf="submitted && editworkshop.isPaid.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="editworkshop.isPaid.errors.required">
                          Price is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label">Register Open</label>
                    <div class="col-md-7">
                      <mat-slide-toggle
                        name="isRegOpen"
                        formControlName="isRegOpen"
                        [ngClass]="{
                          'is-invalid':
                            submitted && editworkshop.isRegOpen.errors
                        }"
                      ></mat-slide-toggle>
                      <div
                        *ngIf="submitted && editworkshop.isRegOpen.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="editworkshop.isPaid.isRegOpen.required">
                          Price is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label">Amount</label>
                    <div class="col-md-7">
                      <input
                        type="text"
                        formControlName="fees"
                        id="price"
                        [ngClass]="{
                          'is-invalid': submitted && editworkshop.fees.errors
                        }"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Price"
                      />
                      <div
                        *ngIf="submitted && editworkshop.fees.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="editworkshop.fees.errors.required">
                          Amount is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Description</label>
                    <ckeditor
                      [editor]="Editor"
                      formControlName="description"
                    ></ckeditor>
                  </div>
                  <div style="text-align: center">
                    <button class="btn btn-sm btn-primary">
                      <i class="fa fa-dot-circle-o"></i> Update
                    </button>
                    <button type="reset" class="btn btn-sm btn-danger">
                      <i class="fa fa-ban"></i> Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <router-outlet></router-outlet>
    </div>
    <!-- <app-az-footer></app-az-footer> -->
  </section>
</div>
