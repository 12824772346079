<app-az-header> </app-az-header>

<app-az-menu></app-az-menu>
<app-loader [Loader]="IsLoading"></app-loader>
<!-- Main content -->

<div class="content-wrapper">
  <section class="content">
    <div class="container-fluid">
      <div class="animated fadeIn">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <i class="fa fa-align-justify"></i> Payment List
              </div>
              <div class="card-body">
                <table class="table" mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="Merchant OrderId">
                    <th *matHeaderCellDef>Merchant OrderId</th>
                    <td *matCellDef="let data">
                      {{ data.merchantOrderId ? data.merchantOrderId : "N/A" }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Company Name">
                    <th *matHeaderCellDef>Company Name</th>
                    <td *matCellDef="let data">
                      {{ data.companyName ? data.companyName : "N/A" }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="EmailId">
                    <th *matHeaderCellDef>EmailId</th>
                    <td *matCellDef="let data">
                      {{ data.emailId ? data.emailId : "N/A" }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Ticket Count">
                    <th *matHeaderCellDef>Ticket Count</th>
                    <td *matCellDef="let data">
                      {{ data.bulkTicketCount ? data.bulkTicketCount : "N/A" }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Price Type">
                    <th *matHeaderCellDef>Price Type</th>
                    <td *matCellDef="let data">
                      {{ data.priceType ? data.priceType : "N/A" }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Price">
                    <th *matHeaderCellDef>Price</th>
                    <td *matCellDef="let data">
                      {{ data.price ? (data.price | currency : "INR") : "N/A" }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Total Price">
                    <th *matHeaderCellDef>Total Price</th>
                    <td *matCellDef="let data">
                      {{
                        data.totalPrice
                          ? (data.totalPrice | currency : "INR")
                          : "N/A"
                      }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Gst Amount">
                    <th *matHeaderCellDef>Gst Amount</th>
                    <td *matCellDef="let data">
                      {{
                        data.gstAmount
                          ? (data.gstAmount | currency : "INR")
                          : "N/A"
                      }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Total Amount">
                    <th *matHeaderCellDef>Total Amount</th>
                    <td *matCellDef="let data">
                      {{
                        data.totalAmount
                          ? (data.totalAmount | currency : "INR")
                          : "N/A"
                      }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Transaction Id">
                    <th *matHeaderCellDef>Transaction Id</th>
                    <td *matCellDef="let data">
                      {{ data.transactionId ? data.transactionId : "N/A" }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Payment URL">
                    <th *matHeaderCellDef>Payment URL</th>
                    <td *matCellDef="let data; let i = index" class="d-flex">
                      <a
                        href="{{ data.paymentURL ? data.paymentURL : 'N/A' }}"
                        target="_blank"
                        >{{ data.paymentURL ? "Url" : "N/A" }}</a
                      >
                      <a *ngIf="data.paymentURL && !showCopied"
                        ><i
                          class="ml-2 fas fa-copy"
                          (click)="copyLink(data.paymentURL, i)"
                        ></i
                      ></a>
                      <ng-container *ngIf="showCopied && i == ShowIndex">
                        <span da class="copied_text">Copied!!</span>
                      </ng-container>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="State">
                    <th *matHeaderCellDef>State</th>
                    <td *matCellDef="let data">
                      {{ data.state ? data.state : "N/A" }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Response Code">
                    <th *matHeaderCellDef>Response Code</th>
                    <td *matCellDef="let data">
                      {{ data.responseCode ? data.responseCode : "N/A" }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Payment Type">
                    <th *matHeaderCellDef>Payment Type</th>
                    <td *matCellDef="let data">
                      {{ data.paymentType ? data.paymentType : "N/A" }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Success">
                    <th *matHeaderCellDef>Success</th>
                    <td *matCellDef="let data">
                      {{ data.success ? data.success : "N/A" }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Code">
                    <th *matHeaderCellDef>Code</th>
                    <td *matCellDef="let data">
                      {{ data.code ? data.code : "N/A" }}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
                <mat-paginator
                  class="pagnacolor"
                  [pageSizeOptions]="pageSizes"
                  showFirstLastButtons
                ></mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
