<app-az-header> </app-az-header>
<app-az-menu></app-az-menu>

<div class="content-wrapper">
  <section class="content">
    <div class="container-fluid">
      <div class="animated fadeIn">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <i class="fa fa-align-justify"></i> Attendees List
              </div>
              <div class="card-body">
                <div class="col-md-3 search">
                  <input type="text" class="form-control search-box" (keyup)="attendeesFilter($event)"
                    placeholder="Search" />
                </div>

                <table class="table" mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="S.No">
                    <th *matHeaderCellDef>S.No</th>
                    <td *matCellDef="let data let i = index "> {{paginator.pageIndex * paginator.pageSize+i+1}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Name">
                    <th *matHeaderCellDef>Name</th>
                    <td *matCellDef="let data">
                      {{ data.fullName ? data.fullName : "N/A" }}
                      {{ data.lastName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Mobile Number">
                    <th *matHeaderCellDef>Phone</th>
                    <td *matCellDef="let data">
                      {{ data.mobileNumber ? data.mobileNumber : "N/A" }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Email">
                    <th *matHeaderCellDef>Email Id</th>
                    <td class="mat-column-email" *matCellDef="let data">
                      {{ data.emailId ? data.emailId : "N/A" }}
                    </td>
                  </ng-container>

                  
                  <ng-container matColumnDef="Amount">
                    <th *matHeaderCellDef>Amount</th>
                    <td *matCellDef="let data">
                      {{
                      data.totalAmount
                      ? (data.totalAmount | currency : "INR")
                      : "N/A"
                      }}
                    </td>
                  </ng-container>
                   
                  <ng-container matColumnDef="Date">
                    <th *matHeaderCellDef>Date</th>
                    <td *matCellDef="let data">
                      {{
                      data.createdDate
                      ? (data.createdDate | utcToIst)
                      : "N/A"
                      }}
                    </td>
                  </ng-container> 

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <mat-paginator class="pagnacolor" [pageSizeOptions]="pageSizes" showFirstLastButtons></mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>