import { Component, OnInit, ViewChild } from "@angular/core";
import { AlertService } from "../../common/Alert/alert/alert.services";
import { AppConfig } from "../../../appConfig/app.config";
import { ApiServicesService } from "../../../Services/api-services.service";

import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
@Component({
  selector: "app-listsponsors",
  templateUrl: "./listsponsors.component.html",
  styleUrls: ["./listsponsors.component.scss"],
})
export class ListsponsorsComponent implements OnInit {
  assginList = [];

  responseData: any;
  pageSizes = [5, 10, 20, 50];
  IsLoading=false;
  displayedColumns: string[] = [
    "S.No",
    "Community Name",
    "Type",
    "Website",
    "Twitter",
    "LinkedIn",
    "Logo",
    "Action",
    "Edit",
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private alert: AlertService,
    private appConfig: AppConfig,
    private apiCall: ApiServicesService
  ) {}

  ngOnInit() {
    this.sponserList();
  }

  sponserList() {
    this.IsLoading=true;
    this.apiCall.getSponsor().subscribe((res) => {
      this.assginList = res;
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = this.assginList;
      this.dataSource.paginator = this.paginator;
      this.IsLoading=false;
    });
  }
  changeStatus(event) {
    this.apiCall
      .StatusChangeSponsers(event.id, event.isActive)
      .subscribe((resdata) => {
        this.responseData = resdata;
      });
  }
  sponsorsFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
