import { Component, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ApiServicesService } from "../../../Services/api-services.service";


@Component({
  selector: "app-pricing",
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.scss"],
})
export class PricingComponent implements OnInit {

  minDate=new Date();


  pricingForm: UntypedFormGroup;
  submitted = false;
  IsLoading=false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiCall: ApiServicesService,
    private route:Router
  ) { }

  ngOnInit(): void {
    this.pricingForm = this.formBuilder.group({
      type: new UntypedFormControl("", Validators.required),
      price: new UntypedFormControl(null, Validators.required),
      benifits: this.formBuilder.array([this.initQuesForm()]),
      lastDate: new UntypedFormControl(),
      discountPercentage: new UntypedFormControl(),
      ticketCount: new UntypedFormControl("", Validators.required),
    });
  }

  initQuesForm(): UntypedFormGroup {
    return this.formBuilder.group({
      ticketbenfit: new UntypedFormControl("", Validators.required),
    });
  }
  getformgroup() {
    return (this.pricingForm.get("benifits") as UntypedFormArray).controls;
  }
  addControl(i: any) {
    if (this.pricingForm.get("benifits")?.valid) {
      const productnames = this.pricingForm.get("benifits") as UntypedFormArray;
      productnames.push(this.initQuesForm()); // add formcontrol
      console.log(JSON.stringify(this.pricingForm.get("benifits")?.value));
    } else {
      this.pricingForm.get("benifits")?.markAllAsTouched();
    }
  }
  //Remove
  removeControl(i: any) {
    const removeQuetion = this.pricingForm.get("benifits") as UntypedFormArray;
    removeQuetion.removeAt(i); // remove formcontrol
  }

  get pricing() {
    return this.pricingForm.controls;
  }

  addpricing() {
    if (this.pricingForm.invalid){
    this.submitted=true
    return;
    }
    else
    {
      this.IsLoading=true;
      const pricingData = {
        benefits:  String(this.pricingForm.get("benifits").value.map((val: any) => val.ticketbenfit)),
        price: Number(this.pricingForm.get("price").value),
        type: this.pricingForm.get("type").value,
        lastDate: this.pricingForm.get("lastDate").value
      }
      this.submitted = true;
      this.apiCall.createTicket(pricingData).subscribe(pri=>{
  if(pri){
    this.IsLoading=false;
    this.route.navigate(['/listprice'])
  }
  this.IsLoading=false;
      })
    }


  }

  reset() { }
}
