import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthuguradServiceService {

  constructor() { }
  getUser() {
    return !!localStorage.getItem("UserId");
  }
}

