import { Component, OnInit } from "@angular/core";

import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AlertService } from "../../common/Alert/alert/alert.services";
import { AppConfig } from "../../../appConfig/app.config";
import { ApiServicesService } from "../../../Services/api-services.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-addsponsors",
  templateUrl: "./addsponsors.component.html",
  styleUrls: ["./addsponsors.component.scss"],
})
export class AddsponsorsComponent implements OnInit {
  sponsorsForm: UntypedFormGroup;
  submitted = false;
  sponsorsLogo: any;
  buttonEnable: any;
  IsLoading = false;
  user_avatar = "../../../../assets/img/logo.png";
  sponsorCategory = [
    { id: 1, name: "Sponsor" },
    { id: 2, name: "Exhibitor" },
  ];
  sponsorType = [
    { id: 1, Name: "Platinum" },
    { id: 2, Name: "Gold" },
    { id: 3, Name: "Community " },
    { id: 4, Name: "Learning" },
    { id: 5, Name: "Media" },
    { id: 6, Name: "Security" },
    { id: 7, Name: "Hackathon" },
    { id: 8, Name: "DigitalBadge" },
    { id: 9, Name: "Silver" },
    { id: 10, Name: "Support" },
    { id: 11, Name: "Carrier" },
    { id: 12, Name: "Powered" },
    { id: 13, Name: "AISkill" },
    { id: 14, Name: "Knowledge" },
    { id: 15, Name: "Ecosystem" },
   
  ];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly alert: AlertService,
    private readonly appConfig: AppConfig,
    private readonly apiCall: ApiServicesService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.sponsorsForm = this.formBuilder.group({
      name: new UntypedFormControl("", Validators.required),
      twitterUrl: new UntypedFormControl(""),
      linkedinUrl: new UntypedFormControl(""),
      logoPath: new UntypedFormControl(""),
      SponsorType: new UntypedFormControl(null, Validators.required),
      sponsorCategory: new UntypedFormControl(null, Validators.required),
      location: new UntypedFormControl(""),
      websiteUrl: new UntypedFormControl("", Validators.required),
      isActive: new UntypedFormControl(true),
      createdBy: new UntypedFormControl(localStorage.getItem("UserId")),
      eventYear: new UntypedFormControl(this.appConfig.GetEventYear),
      createdDate: new UntypedFormControl(new Date()),
    });
  }
  get sponsors() {
    return this.sponsorsForm.controls;
  }

  addsponsors() {
    this.submitted = true;

    if (this.sponsorsForm.invalid) {
      return;
    }
    this.IsLoading = true;
    this.sponsorsForm
      .get("SponsorType")
      .setValue(Number(this.sponsorsForm.get("SponsorType").value));
    this.sponsorsForm
      .get("sponsorCategory")
      .setValue(Number(this.sponsorsForm.get("sponsorCategory").value));

    this.apiCall.addSponsors(this.sponsorsForm.value).subscribe((data) => {
      console.log(data);
      if (data) {
        this.IsLoading = false;
        this.alert.success("Success");
        this.router.navigate(["/ListSponsors"]);
      } else {
        this.IsLoading = false;
        this.alert.error("Something Went Wrong");
      }
      (error) => {
        this.IsLoading = false;
        this.alert.error(error);
      };
    });
  }
  reset() {}
  sponsorLogo(image: FileList) {
    const img = image[0].name.split(".").pop();
    if (
      img === "png" ||
      img === "PNG" ||
      img === "jpg" ||
      img === "JPG" ||
      img === "jpeg" ||
      img === "gif" ||
      img === "psd" ||
      img === "bmp"
    ) {
      this.IsLoading = true;
      for (let _files in image) {
        if (_files != "length" && _files != "item") {
          this.sponsorsLogo = image.item(Number(_files));
          const formData = new FormData();
          formData.append("photo", this.sponsorsLogo);
          formData.append("EventYear", this.appConfig.GetEventYear);
          this.apiCall.sponsorLogo(formData).subscribe((res) => {
            if (res != null || res != undefined) {
              console.log(res);

              this.sponsorsForm.get("logoPath").setValue(res);
              this.user_avatar = this.sponsorsForm.get("logoPath").value;
              this.buttonEnable = true;
              this.IsLoading = false;
            }
            this.IsLoading = false;
          });
        }
      }
    } else {
    }
  }
}
