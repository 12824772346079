<app-az-header> </app-az-header>
<app-alert></app-alert>
<app-az-menu></app-az-menu>
<app-loader [Loader]="IsLoading"></app-loader>
<div class="content-wrapper">
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Create Bulk ticket</h3>
            </div>

            <form
            [formGroup]="bulkCreateTicketForm"
            (ngSubmit)="CreateTicket()"
            class="form-horizontal"
          >
            <div class="form-group col-md-7">
              <label>Ticket Count</label>
              <input
                [ngClass]="{
                  'is-invalid': submitted && bulkTicketForm.totalticket.errors
                }"
                type="text"
                formControlName="totalticket"
                id="count"
                autocomplete="off"
                class="form-control"
                placeholder="Total Ticket"
                [NumberOnly]="false"
                appNumbericOnly
                (blur)="checkGst()"
              />

              <div
                *ngIf="submitted && bulkTicketForm.totalticket.errors"
                class="invalid-feedback"
              >
                <div *ngIf="bulkTicketForm.totalticket.errors.required">
                  Ticket Count is required
                </div>
              </div>
            </div>
            <div class="form-group col-md-7">
              <label>Per Ticket Amount</label>
              <input
                [ngClass]="{
                  'is-invalid': submitted && bulkTicketForm.ticketAmount.errors
                }"
                type="text"
                formControlName="ticketAmount"
                id="count"
                autocomplete="off"
                class="form-control"
                placeholder="Enter Without Gst Amount"
                appNumbericOnly
                [NumberOnly]="false"
                (blur)="checkGst()"
              />

              <div
                *ngIf="submitted && bulkTicketForm.ticketAmount.errors"
                class="invalid-feedback"
              >
                <div *ngIf="bulkTicketForm.ticketAmount.errors.required">
                  Amount is required
                </div>
              </div>
            </div>

            <div class="form-group col-md-7">
              <label>Ticket Type</label>
              <select
                class="form-control select2"
                formControlName="priceType"
              >
                <option value="" disabled>Select Type</option>
                <option
                  *ngFor="let data of priceType"
                  [value]="data.type"
                >
                  {{ data.type }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-7">
              <label>Company Name</label>
              <input
                [ngClass]="{
                  'is-invalid': submitted && bulkTicketForm.companyName.errors
                }"
                type="text"
                formControlName="companyName"
                id="count"
                autocomplete="off"
                class="form-control"
                placeholder="Company Name"
                appNumbericOnly
              />

              <div
                *ngIf="submitted && bulkTicketForm.companyName.errors"
                class="invalid-feedback"
              >
                <div *ngIf="bulkTicketForm.companyName.errors.required">
                  Company name is required
                </div>
              </div>
            </div>

            <div class="form-group col-md-7">
              <label>Email Id</label>
              <input
                [ngClass]="{
                  'is-invalid': submitted && bulkTicketForm.emailId.errors
                }"
                type="text"
                formControlName="emailId"
                id="count"
                autocomplete="off"
                class="form-control"
                placeholder="Email Id"
                appNumbericOnly
              />

              <div
                *ngIf="submitted && bulkTicketForm.emailId.errors"
                class="invalid-feedback"
              >
                <div *ngIf="bulkTicketForm.emailId.errors.required">
                  Email id is required
                </div>

                  <div *ngIf="bulkTicketForm.emailId.errors.pattern"
                    >Invalid mail pattern</div
                  >

              </div>
            </div>
            <div class="form-group col-md-7">
              <label>GST Number</label>
              <input
                [ngClass]="{
                  'is-invalid': submitted && bulkTicketForm.gstNumber.errors
                }"
                type="text"
                formControlName="gstNumber"
                id="count"
                autocomplete="off"
                class="form-control"
                placeholder="GST Number"
                appNumbericOnly
              />

              <div
                *ngIf="submitted && bulkTicketForm.gstNumber.errors"
                class="invalid-feedback"
              >
                <div *ngIf="bulkTicketForm.gstNumber.patternrequired">
                  Invalid GST Number E.x [22AAAAA0000A1Z5]
                </div>
              </div>
            </div>
            <div class="form-group col-md-7">
              <label>Company Address</label>
              <input
                [ngClass]="{
                  'is-invalid': submitted && bulkTicketForm.address.errors
                }"
                type="text"
                formControlName="address"
                id="count"
                autocomplete="off"
                class="form-control"
                placeholder="Company Address"
                appNumbericOnly
              />

              <div
                *ngIf="submitted && bulkTicketForm.address.errors"
                class="invalid-feedback"
              >
                <div *ngIf="bulkTicketForm.address.errors.required">
                  Address is required
                </div>
              </div>
            </div>
            <div class="form-group col-md-7">
              <label>Coupon Code</label>
              <input
                type="text"
                formControlName="couponCode"
                id="count"
                autocomplete="off"
                class="form-control"
                placeholder="Coupon Code"
              />
            </div>
            <div class="form-group col-md-7">
              <label>Payment Method</label>
              <div class="d-flex">
                <div class="form-check mr-3">
                  <input
                    type="radio"
                    formControlName="paymentMethod"
                    id="phonePe"
                    name="paymentMethod"
                    value="PhonePe"
                    class="form-check-input"
                  />
                  <label for="phonePe" class="form-check-label">PhonePe</label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    formControlName="paymentMethod"
                    id="razorpay"
                    name="paymentMethod"
                    value="Razorpay"
                    class="form-check-input"
                  />
                  <label for="razorpay" class="form-check-label">Razorpay</label>
                </div>
              </div>
              <div
              *ngIf="submitted && bulkTicketForm.paymentMethod.errors"
              class="invalid"
            >
              <div *ngIf="bulkTicketForm.paymentMethod.errors.required">
                PaymentMethod is required
              </div>
            </div>
            </div>
            <div class="row w-100">
              <ng-container>
                <div
                  class="col-md-6 offset-md-6 col-12 offset-0 mt-md-4 p-0 pl-4"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h6 class="text-secondary m-0">Total Ticket Amount</h6>
                    <h6 class="m-0">{{ ticketAmount | currency : "INR" }}</h6>
                  </div>
                </div>
              </ng-container>
              <div class="col-md-6 offset-md-6 col-12 offset-0 mt-3 p-0 pl-4">
                <div
                  class="d-flex align-items-center justify-content-between"
                >
                  <h6 class="text-secondary m-0">GST 18%</h6>
                  <h6 class="m-0">
                    {{ gstAmount | currency : "INR" }}
                  </h6>
                </div>
              </div>

              <ng-container *ngIf="isCoupon">
                <div
                  class="col-md-6 offset-md-6 col-12 offset-0 mt-3 p-0 pl-4"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h6 class="m-0 font-weight-bold">Total Amount</h6>
                    <h6 class="m-0 font-weight-bold">
                      {{ totalCouponAmount | currency : "INR" }}
                    </h6>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="isCoupon">
                <div
                  class="col-md-6 offset-md-6 col-12 offset-0 mt-3 p-0 pl-4"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h6 class="text-secondary m-0">Discount Amount</h6>
                    <h6 class="m-0 text-success">
                      {{ discountAmount | currency : "INR" }}
                    </h6>
                  </div>
                </div>
              </ng-container>
              <div class="col-md-6 offset-md-6 col-12 offset-0 mt-3 p-0 pl-4">
                <div
                  class="d-flex align-items-center justify-content-between"
                >
                  <h6 class="m-0 font-weight-bold">Grand Total</h6>
                  <h6 class="m-0 font-weight-bold">
                    {{ totalAmount | currency : "INR" }}
                  </h6>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <span class="float-right">
                <button type="submit" class="btn btn-danger ml-2">Reset</button>
                <button type="submit" class="btn btn-success">Create</button>
              </span>
            </div>
          </form>

          </div>
        </div>
      </div>
    </div>
  </section>
</div>
