import { Component, OnInit } from '@angular/core';

import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiServicesService } from '../../../Services/api-services.service'
import { Router } from '@angular/router';
import { AlertService } from '../../common/Alert/alert/alert.services';
@Component({
  selector: 'app-volunteers-add',
  templateUrl: './volunteers-add.component.html',
  styleUrls: ['./volunteers-add.component.scss']
})
export class volunteersAddComponent implements OnInit {
  VolunteersForm: UntypedFormGroup;
  submitted = false;
  IsLoading=false
  user_avatar='../../../../assets/img/logo.png'
  photo: any
  volunteertype=[{'id':1,'Name':'Organizer'},{'id':2,'Name':'EventCrew'},{'id':3,'Name':'Volunteer'},{'id':4,'Name':'StudentAmbassador'},{'id':5,'Name':'Advisers'},{'id':6,'Name':'Co-Chairs'}]
  buttonEnable = false

  options = {
    autoClose: true
  };
  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiCall: ApiServicesService,
    private router: Router,
    private alert: AlertService
  ) { }

  ngOnInit(
  ) {
    this.VolunteersForm = this.formBuilder.group({

      firstName: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      designation: new UntypedFormControl('', Validators.required),
      organization: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      twitterUrl: new UntypedFormControl(''),
      volunteertype: new UntypedFormControl(null,Validators.required),
      linkedinUrl: new UntypedFormControl(''),
      photoPath: new UntypedFormControl(''),
      isActive: new UntypedFormControl(true),
      createdBy: new UntypedFormControl(localStorage.getItem('UserId')),
      eventYear: new UntypedFormControl(new Date().getFullYear().toString()),
      createdDate: new UntypedFormControl(new Date()),
      emailId:new UntypedFormControl('',[Validators.required,Validators.pattern("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")]),
      mobileNumber:new UntypedFormControl('',Validators.required)
    })

  }
  get Volunteer() { return this.VolunteersForm.controls; }

  registervolunteers() {
    this.submitted = true;


    if (this.VolunteersForm.invalid) {
      return;
    }
    this.IsLoading=true;
    this.VolunteersForm.get('volunteertype').setValue(Number(this.VolunteersForm.get('volunteertype').value))

    this.apiCall.volunteersRegister(this.VolunteersForm.value).subscribe(res => {

      if (res != null || res != undefined) {

        this.IsLoading=false;
        this.alert.success("Volunteers Added!", this.options)
        this.router.navigate(['/ListVolunteers'])
      }
      else {
        error => {
          this.alert.error(error, this.options)
        }
        this.IsLoading=false;
      }
    })



  }
  volunterImage(image: FileList) {
    const img = image[0].name.split('.').pop();
    if (img === 'png' || img === 'PNG' || img === 'jpg' || img === 'JPG' || img === 'jpeg' || img === 'gif' || img === 'psd' || img === 'bmp') {
      this.IsLoading=true;
      for (let _files in image) {
        if (_files != "length" && _files != "item") {
          this.photo = image.item(Number(_files));
          const formData = new FormData();
          formData.append("photo", this.photo)
          formData.append("EventYear", new Date().getFullYear().toString())
          this.apiCall.UploadVolunteersImage(formData).subscribe(res => { 
            if (res != null || res != undefined) {
              this.VolunteersForm.get('photoPath').setValue(res);
              this.buttonEnable = true
              this.user_avatar=this.VolunteersForm.get('photoPath').value
              this.IsLoading=false;
            }
            this.IsLoading=false;
          })
        }

      }

    }
    else {

    }


  }

}
