<app-az-header> </app-az-header>
<app-alert></app-alert>
<app-az-menu></app-az-menu>
<app-loader [Loader]="loader"></app-loader>

<div class="app-body">
  <!-- Main content -->

  <div class="content-wrapper">
    <section class="content">
      <div class="container-fluid">
        <div class="row col-lg-12">
          <div class="form-group col-lg-6">
            <form [formGroup]="paymentStatusForm" (ngSubmit)="CheckStatus()" class="form-horizontal">

              <label>Order ID</label>
              <input [ngClass]="{
                  'is-invalid': submitted && paymentCheckForm.orderId.errors
                }" type="text" formControlName="orderId" id="count" autocomplete="off" class="form-control"
                placeholder="Order ID" />

              <div *ngIf="submitted && paymentCheckForm.orderId.errors" class="invalid-feedback">
                <div *ngIf="paymentCheckForm.orderId.errors.required">
                  Order is required
                </div>
              </div>
              <div class="col-lg-6" style="text-align: center">
                <button class="btn btn-sm btn-primary">
                  <i class="fa fa-dot-circle-o"></i> Get Payment Status
                </button>
              </div> 
            </form> 
          </div>
         
        </div>

        <div class="row" *ngIf="returnPaymentStatus!==null">
          <div class="col-12 table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Payment Status</th>
                  <th>Code</th>
                  <th>Message</th>
                  <th>Merchant Id</th>
                  <th>Merchant TransAction Id</th>
                  <th>Transaction Id</th>
                  <th>Amount</th>
                  <th>State</th>
                  <th>Response Code</th>
                </tr>
              </thead>
              <tbody>
                <tr returnPaymentStatus>
                  <td>{{ returnPaymentStatus?.success }}</td>
                  <td>{{ returnPaymentStatus?.code }}</td>
                  <td>{{ returnPaymentStatus?.message }}</td>
                  <td>{{ returnPaymentStatus?.data?.merchantId }}</td>
                  <td>{{ returnPaymentStatus?.data?.merchantTransActionId }}</td>
                  <td>{{ returnPaymentStatus?.data?.transactionId }}</td>
                  <td>{{ returnPaymentStatus?.data?.amount }}</td>
                  <td>{{ returnPaymentStatus?.data?.state }}</td>
                  <td>{{ returnPaymentStatus?.data?.responseCode }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>