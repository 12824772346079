import { Component, OnInit, ViewChild } from "@angular/core";

import { ApiServicesService } from "../../../Services/api-services.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
@Component({
  selector: "app-volunteers-list",
  templateUrl: "./volunteers-list.component.html",
  styleUrls: ["./volunteers-list.component.scss"],
})
export class volunteersListComponent implements OnInit {
  public sidebarMinimized = false;

  responseData: any;
  sliderChecked = true;
  IsLoading=false;
  pageSizes = [5, 10, 20, 50];
  displayedColumns: string[] = [
    "S.No",
    "Volunteer Name",
    "Designation",
    "Image",
    "LinkedIn",
    "Twitter",
    "Action",
    "Edit",
  ];
  //dataSources = new MatTableDataSource(ELEMENT_DATA);
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private apiCall: ApiServicesService,
  ) {}

  ngOnInit() {
    this.getVolunteersList();
  }

  getVolunteersList() {
    this.IsLoading=true;
    this.apiCall.getVolunteersList().subscribe((resdata) => {
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = resdata;
      this.dataSource.paginator = this.paginator;
      this.IsLoading=false;
    });
    this.IsLoading=false;
  }
  changeStatus(event) {
    this.apiCall
      .StatusChangeVolunteer(event.id, event.isActive)
      .subscribe((resdata) => {});
  }
  volunteersFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
