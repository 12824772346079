import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { throwError as observableThrowError, Observable, from } from "rxjs";
import { AppConfig } from "../appConfig/app.config";
import { FormatString } from "./helperclass";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../../src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class ApiServicesService {
  config: object;
  Communitesedit: { name; data };
  private edit = new BehaviorSubject([]);
  shareddata = this.edit.asObservable();
  constructor(private http: HttpClient, private appConfig: AppConfig) {}

  getApiUrl(apiMethods: string): string {
    const baseUrl =
      apiMethods.indexOf("api/notification", 0) > 0 ? "" : environment.apiUrl;
    return baseUrl + apiMethods;
  }
  sharedata(data: any) {
    this.edit.next(data);
  }
  public volunteersRegister(formData): Observable<any> {
    return this.http.post(`${this.appConfig.apiURL}${this.appConfig.PostVolunteer}`, formData, { responseType: 'text' });
  }

  public volunteersUpdate(formData): Observable<any> {
    return this.http.put(`${this.appConfig.apiURL}${this.appConfig.UpdateVolunteer}`, formData);
  }
  public StatusChangeVolunteer(id, status): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.StatusChangeVolunteer,
      id,
      status
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }

  public UploadVolunteersImage(formData): Observable<any> {
    return this.http.post(`${this.appConfig.apiURL}${this.appConfig.PostImageVolunteer}`, formData);
  }

  public UserLogin(userName, Password): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.UserLogin,
      userName,
      Password
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }

  public GetVolunteers(userId): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.GetVolunteerByid,
      userId,
      this.appConfig.GetEventYear
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }

  public getVolunteersList(): Observable<any> {
    const _parameterURL = FormatString(this.appConfig.GetAllVolunteer, this.appConfig.GetEventYear);
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }

  //#region  Communitity
  public StatusChangeCommunities(id, status): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.StatusChangeCommunity,
      id,
      status
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }
  public uploadCommunitiesImage(formData): Observable<any> {
    return this.http.post(`${this.appConfig.apiURL}${this.appConfig.uploadCommunityImage}`, formData);
  }
  public CommunitiesRegister(communites): Observable<any> {
    return this.http.post(`${this.appConfig.apiURL}${this.appConfig.createCommunity}`, communites, { responseType: 'text' });
  }
  public getCommunities(): Observable<any> {
    const _parameterURL = FormatString(this.appConfig.getCommunity, "all");
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }
  public updateCommunities(communtiesform): Observable<any> {
    return this.http.put(`${this.appConfig.apiURL}${this.appConfig.updateCommunity}`, communtiesform);
  }
  //#endregion

  //#regin Sponsors

  public addSponsors(sponserData): Observable<any> {
    return this.http.post(`${this.appConfig.apiURL}${this.appConfig.addSponsors}`, sponserData, { responseType: 'text' });
  }
  public sponsorLogo(formData): Observable<any> {
    return this.http.post(`${this.appConfig.apiURL}${this.appConfig.uploadSponsorImage}`, formData);
  }

  public getSponsor(): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.getSponser,
      this.appConfig.GetEventYear
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }
  public StatusChangeSponsers(id, status): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.StatusChangeSponsers,
      id,
      status
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }
  //#endregion

  //#region Speakers
  public GetSpeakersList(): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.GetSpeakersList,
      this.appConfig.GetEventYear
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }

  public StatusChangeSpeakers(id, status): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.StatusChangeSpeakers,
      id,
      status
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }

  //#endregion

  //#region Sessionize
  public GetSessionize(): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.GetSessionize,
      this.appConfig.GetEventYear
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }

  public AddSessionize(formData): Observable<any> {
    return this.http.post(`${this.appConfig.apiURL}${this.appConfig.CreateSessionize}`, formData, { responseType: 'text' });
  }
  public SyncSessionize(SyncType): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.SyncSessionize,
      this.appConfig.GetEventYear,
      SyncType
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }
  //#endregion
  //#region sponseredit
  public GetSponserById(userId): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.GetSponsorIdid,
      userId,
      this.appConfig.GetEventYear
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }
  public updatesponser(sponsorForm): Observable<any> {
    return this.http.put(`${this.appConfig.apiURL}${this.appConfig.sponsorUpdate}`, sponsorForm);
  }
  //#end sponseredit

  //WorkShop

  public getWorkshoplist(): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.WokshopList,
      this.appConfig.GetEventYear
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }

  public getWorkShopdetails(id) {
    const _parameterURL = FormatString(
      this.appConfig.individualWorkShopList,
      id,
      this.appConfig.GetEventYear
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }
  public addWorkShop(workshopdata): Observable<any> {
    return this.http.post(`${this.appConfig.apiURL}${this.appConfig.InsertWorkShop}`, workshopdata, { responseType: 'text' });
  }
  public StatusChangeWorkshop(id, status): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.StatusChangeWorkshop,
      id,
      status
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }
  //Live Session

  public getSessionDetails(): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.getLivesession,
      this.appConfig.GetEventYear
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }
  public sendNotifications(notificationdata): Observable<any> {
    return this.http.post(`${this.appConfig.apiURL}${
      this.appConfig.NotificationFunctionUrl}`,
      notificationdata
    );
  }

  public UpdateSessionIdInLive(trackName, sessionId): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.UpdateSessionIdInLiveUrl,
      trackName,
      sessionId
    );
    return this.http.put(`${this.appConfig.apiURL}${_parameterURL}`, {});
  }

  //Ticket

  public createTicket(ticket): Observable<any> {
    return this.http.post(`${this.appConfig.apiURL}${this.appConfig.CreateTicket}`, ticket, { responseType: 'text' });
  }

  public getPrice(paymentType?: any, isAdmin?: any): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.getPriceDetails,
      paymentType,
      isAdmin,
      this.appConfig.GetEventYear
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }
  public statusChangeprice(id, status?: any): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.statusChangeprice,
      id,
      status
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }
  public priceUpdate(price): Observable<any> {
    return this.http.put(`${this.appConfig.apiURL}${this.appConfig.updatePrice}`, price);
  }

  //Coupon
  public couponCreate(coupon): Observable<any> {
    return this.http.post(`${this.appConfig.apiURL}${this.appConfig.couponcreate}`, coupon, { responseType: 'text' });
  }

  public getCoupon(): Observable<any> {
    return this.http.get(`${this.appConfig.apiURL}${this.appConfig.getCoupan}`);
  }

  public statusChangeCoupon(id, status): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.statusChangeCoupon,
      id,
      status
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }
  public couponUpdate(coupon): Observable<any> {
    return this.http.put(`${this.appConfig.apiURL}${this.appConfig.updateCoupon}`, coupon);
  }
  public createFaq(faq): Observable<any> {
    return this.http.post(`${this.appConfig.apiURL}${this.appConfig.createFaq}`, faq, { responseType: 'text' });
  }

  public getFaq(): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.getFaqList,
      "",
      this.appConfig.GetEventYear
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }
  public FaqUpdate(faq): Observable<any> {
    return this.http.put(`${this.appConfig.apiURL}${this.appConfig.faqUpdate}`, faq);
  }

  public getNotPaidAttendees(): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.GetNotPaidAttendees,
      this.appConfig.GetEventYear
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }

  public getPaidAttendees(): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.GetPaidAttendees,
      this.appConfig.GetEventYear
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }

  //PaymentGateway


  public coupanValidate(coupanCode: any): Observable<any> {
    const _parameterURL = FormatString(
      this.appConfig.coupanValidate,
      coupanCode
    );
    return this.http.get(`${this.appConfig.apiURL}${_parameterURL}`);
  }

 

 
}
