import { Component, OnInit, ViewChild } from "@angular/core";

import { ApiServicesService } from "../../Services/api-services.service";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
@Component({
  selector: "app-workshoplist",
  templateUrl: "./workshoplist.component.html",
  styleUrls: ["./workshoplist.component.scss"],
})
export class WorkshoplistComponent implements OnInit {
  communtiesList = [];
  workshop: any;

  pageSizes = [5, 10, 20, 50];
  displayedColumns: string[] = [
    "S.No",
    "WorkShop Titles",
    "Action",
    "isActive",
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private apiCall: ApiServicesService, private router: Router) {}

  ngOnInit(): void {
    this.getList();
  }
  getList() {
    this.apiCall.getWorkshoplist().subscribe((dat) => {
      console.log(dat);
      this.workshop = dat;
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = this.workshop;
      this.dataSource.paginator = this.paginator;
    });
  }
  editWorkshop(editId) {
    const editDetails = this.workshop.filter((f) => f.id === editId);
    this.apiCall.sharedata(editDetails);
    // this.Data.emit(editDetails)
    this.router.navigate(["/editworkshop", editId]);
  }
  workshopFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  changeStatus(event) {
    this.apiCall
      .StatusChangeWorkshop(event.id, event.isActive)
      .subscribe((resdata) => {});
  }
}
