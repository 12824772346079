<app-az-header> </app-az-header>
<app-alert></app-alert>

<app-az-menu></app-az-menu>
<app-loader [Loader]="IsLoading"></app-loader>
<div class="content-wrapper">
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Edit Community</h3>
            </div>
            <form
              [formGroup]="editCommunitiesForm"
              (ngSubmit)="updateCommunties()"
            >
              <div class="card-body">
                <div class="form-group">
                  <label>Community Name</label>
                  <input
                    class="form-control"
                    placeholder="Community Name"
                    type="text"
                    formControlName="name"
                    id="name"
                    [ngClass]="{
                      'is-invalid': submitted && editCommunities.name.errors
                    }"
                    autocomplete="off"
                  />

                  <div
                    *ngIf="submitted && editCommunities.name.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="editCommunities.name.errors.required">
                      Community Name is required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>About</label>
                  <input
                    placeholder="About Your Community"
                    type="text"
                    formControlName="about"
                    id="about"
                    [ngClass]="{
                      'is-invalid': submitted && editCommunities.about.errors
                    }"
                    autocomplete="off"
                    class="form-control"
                  />
                  <div
                    *ngIf="submitted && editCommunities.about.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="editCommunities.about.errors.required">
                      About is required
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Select Type</label>
                  <select
                    class="form-control select2"
                    formControlName="communityType"
                  >
                    <option value="null" disabled>Select Type</option>
                    <option
                      *ngFor="let data of Communitytype"
                      [value]="data.id"
                    >
                      {{ data.Name }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label>Website URL</label>
                  <input
                    placeholder="Website URL"
                    type="text"
                    formControlName="websiteUrl"
                    id="websiteUrl"
                    [ngClass]="{
                      'is-invalid':
                        submitted && editCommunities.websiteUrl.errors
                    }"
                    autocomplete="off"
                    class="form-control"
                  />
                  <div
                    *ngIf="submitted && editCommunities.websiteUrl.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="editCommunities.websiteUrl.errors.required">
                      Website is required
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Twitter</label>
                  <input
                    placeholder="Twitter"
                    type="text"
                    formControlName="twitterUrl"
                    id="twitter"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>Linked In</label>
                  <input
                    placeholder="Linked In"
                    type="text"
                    formControlName="linkedinUrl"
                    id="linkedin"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Contact Person</label>
                  <input
                    placeholder="Contact Person"
                    type="text"
                    formControlName="contactPerson"
                    id="contactperson"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Email Id</label>
                  <input
                    placeholder="Email ID"
                    type="text"
                    formControlName="emailId"
                    id="emailId"
                    autocomplete="off"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && editCommunities.emailId.errors
                    }"
                  />
                  <div
                    *ngIf="submitted && editCommunities.emailId.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="editCommunities.emailId.errors.required">
                      Email id is required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Mobile Number</label>
                  <input
                    placeholder="Mobile Number"
                    type="text"
                    formControlName="mobileNumber"
                    id="mobileNumber"
                    autocomplete="off"
                    class="form-control"
                    [NumberOnly]="false"
                    appNumbericOnly
                  />
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleInputFile">Community Logo</label>
                      <div class="input-group">
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            accept="image/x-png,image/gif,image/jpeg"
                            (change)="communitiesImage($event.target.files)"
                            id="image"
                          />
                          <label
                            class="custom-file-label"
                            for="exampleInputFile"
                            >Choose file</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <img
                      class="avatar-user avatar img-style"
                      src="{{
                        user_avatar ? user_avatar : 'assets/img/avatars/6.jpg'
                      }}"
                    />
                  </div>
                </div>
              </div>
              <!-- /.card-body -->

              <div class="card-footer">
                <span class="float-right">
                  <button type="submit" class="btn btn-primary">Update</button>
                  <button
                    type="submit"
                    (click)="reset()"
                    class="btn btn-danger ml-2"
                  >
                    Reset
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
