<app-alert></app-alert>

<div class="hold-transition login-page">
  <div class="login-box">
    <div class="login-logo">
      <a><b>Admin</b></a>
    </div>
    <!-- /.login-logo -->
    <div class="card">
      <div class="card-body login-card-body">
        <p class="login-box-msg">Sign in to start your session</p>

        <form  [formGroup]="UserLoginForm"
        (ngSubmit)="userLogin()">
          <div class="input-group mb-3">
            <input type="email" class="form-control"   formControlName="email"  placeholder="Email" />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
            <span *ngIf="IsLogin && login.email.errors" class="invalid">
              <span *ngIf="login.email.errors.required"
                >Email ID is required</span
              >
              <span *ngIf="login.email.errors.pattern"
                >Invalid mail pattern</span
              >
            </span>
          </div>
          <div class="input-group mb-3">
            <input
            formControlName="password"
              type="password"
              class="form-control"
              placeholder="Password"
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
            <span
            *ngIf="IsLogin && login.password.errors"
            class="invalid"
          >
            <span *ngIf="login.password.errors.required"
              >Password is required</span
            >
          </span>
          </div>
          <div class="row">
            <div class="col-8">
              <div class="icheck-primary">
                <input type="checkbox" id="remember" />
                <label for="remember"> Remember Me </label>
              </div>
            </div>
            <!-- /.col -->
            <div class="col-4">
              <button type="submit" class="btn btn-primary btn-block">
                Sign In
              </button>
            </div>
            <!-- /.col -->
          </div>
        </form>

        <div class="social-auth-links text-center mb-3">
          <p>- OR -</p>
          <a class="btn btn-block btn-primary">
            <i class="fab fa-facebook mr-2"></i> Sign in using Facebook
          </a>
          <a class="btn btn-block btn-danger">
            <i class="fab fa-google-plus mr-2"></i> Sign in using Google+
          </a>
        </div>
        <!-- /.social-auth-links -->

        <p class="mb-1">
          <a>I forgot my password</a>
        </p>
        <p class="mb-0">
          <a class="text-center">Register a new membership</a>
        </p>
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>
  <!-- /.login-box -->
</div>

<!-- container -->
