import { Component, OnInit,Output,EventEmitter,ViewChild } from '@angular/core';

import { ApiServicesService } from '../../../Services/api-services.service'
import { AlertService } from '../../common/Alert/alert/alert.services';
import { AppConfig } from '../../../appConfig/app.config';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-az-speakers-list',
  templateUrl: './az-speakers-list.component.html',
  styleUrls: ['./az-speakers-list.component.scss']
})
export class AzSpeakersListComponent implements OnInit {

  SpeakersList=[]
  // @Output() Data = new EventEmitter<any>();

  pageSizes = [5,10,20,50]
  displayedColumns: string[] = ['S.No','Name','Designation','Image','LinkedIn','Twitter','Action'];

  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator,{static: true}) paginator: MatPaginator;

  constructor(

    private apiCall: ApiServicesService,
    private router:Router,
    private alert:AlertService,
    private appConfig:AppConfig
  ) { }

  ngOnInit() {
    this.getCommuntiesAllList()
  }


getCommuntiesAllList(){
  this.apiCall.GetSpeakersList().subscribe(res=>{
     this.SpeakersList=res
    this.dataSource = new MatTableDataSource();
    this.dataSource.data =this.SpeakersList;
    this.dataSource.paginator = this.paginator;

  })
}


changeStatus(event){
  this.apiCall.StatusChangeSpeakers(event.id,event.isActive).subscribe(resdata=>{
  })
}

speakersFilter(event: Event) {

  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();

  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
}
}
