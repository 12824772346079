<app-az-header>
</app-az-header>
<app-az-menu>

</app-az-menu>

<app-loader [Loader]="IsLoading"></app-loader>
<div class="content-wrapper">
  <section class="content">

      <div class="container-fluid">
        <div class="animated fadeIn">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-header">
                    <i class="fa fa-align-justify"></i> Coupon Details
                  </div>
                  <div class="card-body">


                    <div class="col-md-3 search">
                      <input type="text"class="form-control search-box"  (keyup)="couponFilter($event)"  placeholder="Search">
                      </div>

                      <table class="table" mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="S.No">
                          <th *matHeaderCellDef>S.No</th>
                          <td *matCellDef="let data; let i = index">
                            {{ paginator.pageIndex * paginator.pageSize + i + 1 }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="Coupon Code">
                          <th *matHeaderCellDef>Coupon Code</th>
                          <td *matCellDef="let data">
                            <span *ngIf="editField != data">
                              {{ data.couponCode ? data.couponCode : "N/A" }}
                            </span>

                            <div class="col-sm-5" *ngIf="editField == data">
                              <input
                                type="text"
                                id="name"
                                autocomplete="off"
                                class="form-control"
                                placeholder="Coupon Code"
                                [(ngModel)]="data.couponCode"
                              />
                            </div>
                          </td>
                          <input class="form-control" placeholder="Test" />
                        </ng-container>

                        <ng-container matColumnDef="Percentage">
                          <th *matHeaderCellDef>Percentage</th>

                          <td *matCellDef="let data">
                            <span *ngIf="editField != data">
                              {{
                                data.percentage
                                  ? (data.percentage / 100 | percent)
                                  : "N/A"
                              }}
                            </span>
                            <div class="col-sm-4" *ngIf="editField == data">
                              <input
                                [NumberOnlyWithDot]="false"
                                appNumbericOnly
                                type="text"
                                [id]="'Percentage' + i"
                                autocomplete="off"
                                class="form-control"
                                placeholder="Percentage"
                                [(ngModel)]="data.percentage"
                              />
                            </div>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="CouponCount">
                          <th *matHeaderCellDef>Coupon Count</th>

                          <td *matCellDef="let data">
                            <span *ngIf="editField != data">
                              {{ data.couponCount ? data.couponCount : "N/A" }}
                            </span>
                            <div class="col-sm-4" *ngIf="editField == data">
                              <input
                                [NumberOnly]="false"
                                appNumbericOnly
                                type="text"
                                [id]="'CouponCount' + i"
                                autocomplete="off"
                                class="form-control"
                                placeholder="Coupon Count"
                                [(ngModel)]="data.couponCount"
                              />
                            </div>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="Action">
                          <th *matHeaderCellDef>Action</th>
                          <td *matCellDef="let data">
                            <mat-slide-toggle
                              [name]="i"
                              [(ngModel)]="data.status"
                              (change)="changeStatus(data)"
                            ></mat-slide-toggle>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="Edit">
                          <th *matHeaderCellDef>Edit</th>
                          <td *matCellDef="let data">
                            <i
                              *ngIf="editField != data"
                              class="fa fa-edit"
                              (click)="updateCoupon(data, i)"
                            ></i>
                            <i
                              *ngIf="editField == data"
                              class="fa fa-check-circle update"
                              (click)="updateCoupons(data, i)"
                            ></i>
                            <i
                              *ngIf="editField == data"
                              class="fa fa-close updatec"
                              (click)="cancel()"
                            ></i>
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr
                          mat-row
                          *matRowDef="let row; columns: displayedColumns"
                        ></tr>
                      </table>
      <mat-paginator class="pagnacolor" [pageSizeOptions]="pageSizes" showFirstLastButtons></mat-paginator>

                  </div>
                </div>
              </div>

            </div>

          </div>

      </div>

</section>
  </div>
