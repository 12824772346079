<app-az-header> </app-az-header>
<app-az-menu></app-az-menu>
<div class="app-body">


  <main class="main">
    <div class="container-fluid">
      <div class="animated fadeIn">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <i class="fa fa-align-justify"></i>Live List
              </div>

              <div class="tabs">
                <div
                  class="tab-2"
                  [ngClass]="{ active: activeIndex === i }"
                  (click)="eventDetails(Datedetails.dateOfEvent)"
                  *ngFor="let Datedetails of Day1Data; let i = index"
                >
                  <label for="tab2-{{ i + 1 }}"
                    >Day {{ i + 1 }} ({{ Datedetails.dateOfEvent }})</label
                  >
                </div>
                <div>
                  <div
                    class="form-group row"
                    *ngFor="let details of trackDetails"
                  >
                    <div class="col-md-2">
                      <span class="intab drop">
                        <p>{{ details.room }}</p>
                      </span>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        (change)="changeSessionTitle($event, details.room)"
                      >
                        <option value="null" disabled>Select Session</option>
                        <option
                          *ngFor="let sesseiondetails of details.sessionDetails"
                        >
                          {{ sesseiondetails.title }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <button
                        class="btn btn-sm btn-primary"
                        (click)="sendNotifications()"
                      >
                        <i class="fa fa-dot-circle-o"></i> Update
                      </button>
                    </div>
                  </div>
                </div>
                <!-- <div class="tab-2">
                          <label for="tab2-2">Day 2</label>
                          <input id="tab2-2" (click)="getDay(2)" name="tabs-two" type="radio">
                          <div>
                            <h4>Day 2</h4>
                            <p>Quisque sit amet turpis leo. Maecenas sed dolor mi. Pellentesque varius elit in neque ornare commodo ac non tellus. Mauris id iaculis quam. Donec eu felis quam. Morbi tristique lorem eget iaculis consectetur. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aenean at tellus eget risus tempus ultrices. Nam condimentum nisi enim, scelerisque faucibus lectus sodales at.</p>
                          </div>
                        </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
