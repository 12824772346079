<app-az-header> </app-az-header>
<app-az-menu></app-az-menu>
<div class="content-wrapper">
  <section class="content">
    <div class="container-fluid">
      <div class="animated fadeIn">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <i class="fa fa-align-justify"></i> Volunteer List
              </div>
              <div class="card-body">
                <div class="col-md-3 search">
                  <input
                    type="text"
                    class="form-control search-box"
                    (keyup)="workshopFilter($event)"
                    placeholder="Search"
                  />
                </div>

                <table class="table" mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="S.No">
                    <th *matHeaderCellDef>S.No</th>
                    <td *matCellDef="let data; let i = index">
                      {{ paginator.pageIndex * paginator.pageSize + i + 1 }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="WorkShop Titles">
                    <th *matHeaderCellDef>WorkShop Title</th>
                    <td *matCellDef="let data">
                      {{ data.title ? data.title : "N/A" }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Action">
                    <th *matHeaderCellDef>Action</th>
                    <td *matCellDef="let data">
                      <i
                        class="fa fa-pencil-square-o"
                        (click)="editWorkshop(data.id)"
                      ></i>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="isActive">
                    <th *matHeaderCellDef>Active</th>
                    <td *matCellDef="let data">
                      <mat-slide-toggle
                        [name]="i"
                        [(ngModel)]="data.isActive"
                        (change)="changeStatus(data)"
                      ></mat-slide-toggle>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
                <mat-paginator
                  class="pagnacolor"
                  [pageSizeOptions]="pageSizes"
                  showFirstLastButtons
                ></mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
