<app-az-header> </app-az-header>
<app-alert></app-alert>

<app-az-menu></app-az-menu>
<app-loader [Loader]="IsLoading"></app-loader>
<!-- Main content -->

<div class="content-wrapper">
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Create Faq</h3>
            </div>
            <form>
              <div class="card-body">
                <form [formGroup]="faqForm" (ngSubmit)="saveFaq()">
                  <div
                    formArrayName="faq"
                    *ngFor="
                      let faq of getFaqForm();
                      let i = index;
                      let first = first;
                      let last = last
                    "
                  >
                    <div
                      class="row input-group input-group-outline my-3"
                      [formGroupName]="i"
                    >
                      <div class="col-md-5">
                        <label class="col-form-label pl-0 ml-0" for="text-input"
                          >Faq Question</label
                        >
                        <textarea
                          type="text"
                          spellcheck="true"
                          formControlName="faqQuestion"
                          id="FaqQuestion"
                          autocomplete="off"
                          class="form-control"
                          placeholder="Faq Qustion"
                        ></textarea>
                        <div
                          *ngIf="
                            faq.get('faqQuestion')?.errors?.required &&
                            submitted
                          "
                          class="is-invalid"
                        >
                          Faq question is required
                        </div>
                      </div>

                      <div class="col-md-5">
                        <label class="col-form-label pl-0 ml-0" for="text-input"
                          >Faq Answer</label
                        >

                        <ckeditor
                          [editor]="Editor"
                          formControlName="faqAnswer"
                          id="Faqanswer"
                        ></ckeditor>

                        <span
                          *ngIf="
                            faq.get('faqAnswer')?.errors?.required && submitted
                          "
                          class="is-invalid"
                          >Faq answer is required</span
                        >
                      </div>

                      <div class="col-md-2 mt-1">
                        <span>
                          <button
                            class="add"
                            type="button"
                            (click)="addFaq(i)"
                            *ngIf="last"
                            mat-fab
                            color="primary"
                            aria-label="Example icon button with a delete icon"
                          >
                            <span><i class="fas fa-plus"></i></span>

                            <!-- <mat-icon>+</mat-icon> -->
                          </button>
                          <button
                            class="remove"
                            type="button"
                            (click)="faqDelete(i)"
                            *ngIf="!(first && last)"
                            mat-fab
                            color="primary"
                            aria-label="Example icon button with a delete icon"
                          >
                            <span><i class="fa fa-times"></i></span>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <label class="col-form-label pl-0 ml-0" for="text-input"
                      >Faq Type</label
                    >
                    <select
                      formControlName="faqType"
                      id="Faqanswer"
                      spellcheck="true"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Faq Answer"
                    >
                      <option value="" disabled>Select Type</option>
                      <option value="General">General</option>
                      <option value="Register">Register</option>
                      <option value="Cloud">Cloud</option>
                    </select>
                    <div
                      *ngIf="submitted && faqForm.controls.faqType.errors"
                      class="is-invalid"
                    >
                      <span *ngIf="faqForm.controls.faqType"
                        >Faq Type is required</span
                      >
                    </div>
                  </div>

                  <div class="card-footer mt-2">
                    <span class="float-right">
                      <button
                        type="submit"
                        (click)="reset()"
                        class="btn btn-danger ml-2"
                      >
                        Reset
                      </button>
                      <button type="submit" class="btn btn-success">
                        Update
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- <app-az-footer></app-az-footer> -->
