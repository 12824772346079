<app-az-header> </app-az-header>
<app-alert></app-alert>

<app-az-menu></app-az-menu>
<app-loader [Loader]="IsLoading"></app-loader>
<div class="content-wrapper">
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Edit Volunteer</h3>
            </div>
            <form
            [formGroup]="VolunteersEditForm" (ngSubmit)="updatevolunteers()"
            >
              <div class="card-body">
                <div class="form-group">
                  <label>First Name</label>
                  <input type="text" formControlName="firstName" id="name"
                        [ngClass]="{ 'is-invalid': submitted && Volunteer.firstName.errors }" autocomplete="off"
                        class="form-control" placeholder="Enter Name">
                      <div *ngIf="submitted && Volunteer.firstName.errors" class="invalid-feedback">
                        <div *ngIf="Volunteer.firstName.errors.required">First name is required</div>
                      </div>
                </div>
                <div class="form-group">
                  <label>Last Name</label>
                  <input type="text" formControlName="lastName" id="name"
                  [ngClass]="{ 'is-invalid': submitted && Volunteer.lastName.errors }" autocomplete="off"
                  class="form-control" placeholder="Enter Name">
                <div *ngIf="submitted && Volunteer.lastName.errors" class="invalid-feedback">
                  <div *ngIf="Volunteer.lastName.errors.required">Last name is required</div>
                </div>
              </div>

                <div class="form-group">
                  <label>Select Type</label>
                  <select
                    class="form-control select2"
                    formControlName="volunteertype"
                  >
                    <option value="null" disabled>Select Type</option>
                    <option
                      *ngFor="let data of volunteertype"
                      [value]="data.id"
                    >
                      {{ data.Name }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label>Designation</label>
                  <input type="text" formControlName="designation" id="Designation"
                  [ngClass]="{ 'is-invalid': submitted && Volunteer.designation.errors }" autocomplete="off"
                  class="form-control" placeholder="Enter Designation">
                <div *ngIf="submitted && Volunteer.designation.errors" class="invalid-feedback">
                  <div *ngIf="Volunteer.designation.errors.required">Designation is required</div>
                </div>
                </div>

                <div class="form-group">
                  <label>Organization</label>
                  <input type="text" formControlName="organization" id="organization"
                        [ngClass]="{ 'is-invalid': submitted && Volunteer.organization.errors }" autocomplete="off"
                        class="form-control" placeholder="Enter Organization">
                      <div *ngIf="submitted && Volunteer.organization.errors" class="invalid-feedback">
                        <div *ngIf="Volunteer.organization.errors.required">Organization is required</div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Email Id</label>
                      <input
                        placeholder="Email ID"
                        type="text"
                        formControlName="emailId"
                        id="emailId"
                        autocomplete="off"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid': submitted && Volunteer.emailId.errors
                        }"
                      />
                      <div
                        *ngIf="submitted && Volunteer.emailId.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="Volunteer.emailId.errors.required">
                          Email id is required
                        </div>
                      </div>

                      <div class="form-group">
                        <label>Mobile Number</label>
                        <input type="text" (keypress)="keyPress($event)" formControlName="mobileNumber" id="mobileNumber"
                        [ngClass]="{ 'is-invalid': submitted && Volunteer.mobileNumber.errors }" autocomplete="off"
                        class="form-control" placeholder="Mobile Number">
                      <div *ngIf="submitted && Volunteer.mobileNumber.errors" class="invalid-feedback">
                        <div *ngIf="Volunteer.mobileNumber.errors.required">Mobile Number is required</div>
                      </div>
                      </div>

                <div class="form-group">
                  <label>Twitter Url</label>
                  <input
                    placeholder="Twitter Url"
                    type="text"
                    formControlName="twitterUrl"
                    id="linkedin"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Linkedin Url</label>
                  <input
                    placeholder="Linkedin Url"
                    type="text"
                    formControlName="linkedinUrl"
                    id="linkedin"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>

                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label  >Image Upload</label>
                      <div class="input-group">
                        <div class="custom-file">
                          <input type="file" (change)="volunterImage($event.target.files)" accept="image/x-png,image/gif,image/jpeg"
                           id="image">
                          <label for="image"
                            class="custom-file-label">Choose file</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <img  class="avatar-user avatar img"
                    src="{{user_avatar}}">
                  </div>
                </div>
              </div>
              <!-- /.card-body -->

              <div class="card-footer">
                <span class="float-right">
                  <button type="submit" class="btn btn-primary">Update</button>
                  <button
                    type="submit"

                    class="btn btn-danger ml-2"
                  >
                    Reset
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
