import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagenotfund',
  templateUrl: './pagenotfund.component.html',
  styleUrls: ['./pagenotfund.component.scss']
})
export class PagenotfundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
