import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ApiServicesService } from "../../../Services/api-services.service";
 
@Component({
  selector: "app-counponcreate",
  templateUrl: "./couponcreate.component.html",
  styleUrls: ["./counponcreate.component.scss"],
})
export class CounponcreateComponent implements OnInit {
  couponForm: UntypedFormGroup;
  submitted = false;
  IsLoading = false;
  messgae: any;

  constructor(
    private form: UntypedFormBuilder,
    private apiCall: ApiServicesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.couponForm = this.form.group({
      couponCode: new UntypedFormControl("", Validators.required),
      percentage: new UntypedFormControl(null, Validators.required),
      couponCount: new UntypedFormControl(),
      status: new UntypedFormControl(),
    });
  }
  get coupon() {
    {
      return this.couponForm.controls;
    }
  }
  createCoupon() {
    this.submitted = true;

    if (this.couponForm.invalid) {
      return;
    }
    this.IsLoading = true;
    this.couponForm.get("status").setValue(true);
    this.couponForm
      .get("couponCount")
      .setValue(
        Number(
          this.couponForm.get("couponCount").value
            ? this.couponForm.get("couponCount").value
            : 0
        )
      );
    this.couponForm
      .get("percentage")
      .setValue(Number(this.couponForm.get("percentage").value));
    console.log(this.couponForm.value);

    this.apiCall.couponCreate(this.couponForm.value).subscribe((coupon) => {

      this.IsLoading = false;
      if (coupon === "Coupon Created") {
        this.router.navigate(["/listcoupon"]);
      }
      else{
      this.messgae = 'Coupon Already Exit';
      this.IsLoading = false;
      }
    });
  }

  reset() {
    this.couponForm.reset();
  }
}
