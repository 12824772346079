import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-az-header",
  templateUrl: "./az-header.component.html",
  styleUrls: ["./az-header.component.scss"],
})
export class AzHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  userLogout() {
    localStorage.removeItem("UserId");
    window.location.href = "/Sigin";
  }
}
