import { environment } from "../../environments/environment";

export class AppConfig {
  public readonly apiURL = environment.apiUrl;
  public readonly paymentURL = environment.paymentApiURL;
  public readonly authkey = "RwBsAG8AYgBhAGwAQQB6AEMAbwBuAGYARABlAHYA";
  public readonly GetEventYear = new Date().getFullYear().toString();
  public readonly GetAllVolunteer = "Volunteer?eventYear={0}";
  public readonly PostVolunteer = "Volunteer";
  public readonly UpdateVolunteer = "Volunteer";
  public readonly PostImageVolunteer = "Volunteer/UploadVolunteerImage";
  public readonly UserLogin = "User?email={0}&password={1}";
  public readonly GetVolunteerByid = "Volunteer/{0}?year={1}";
  public readonly StatusChangeVolunteer =
    "Volunteer/StatusChangeVolunteer?id={0}&status={1}";
  public readonly StatusChangeCommunity =
    "Community/StatusChangeCommunity?id={0}&status={1}";
  public readonly uploadCommunityImage = "Community/UploadCommunityImage";
  public readonly createCommunity = "Community/CreateCommunity";
  public readonly getCommunity = "Community?eventYear={0}";
  public readonly updateCommunity = "Community";
  public readonly GetSessionize = "Sessionize/GetSessionize?eventYear={0}";
  public readonly CreateSessionize = "Sessionize/AddSessionize";
  public readonly addSponsors = "Sponsor";
  public readonly uploadSponsorImage = "Sponsor/UploadSponsorImage";
  public readonly getSponser = "Sponsor?eventYear={0}";
  public readonly StatusChangeSponsers =
    "Sponsor/StatusChangeSponsor?id={0}&status={1}";
  public readonly GetSpeakersList = "Speaker?eventYear={0}";
  public readonly StatusChangeSpeakers =
    "Speaker/StatusChangeSpeakers?id={0}&status={1}";
  public readonly StatusChangeWorkshop =
    "Workshop/StatusChangeWorkshop?id={0}&status={1}";

  public readonly SyncSessionize =
    "Sessionize/SyncSessionize?eventYear={0}&SyncType={1}";
  public readonly GetSponsorIdid = "Sponsor/{0}?year={1}";
  public readonly sponsorUpdate = "Sponsor";
  //WorkShop
  public readonly WokshopList = "Workshop/GetAdminAllWorkshops?eventYear={0}";
  public readonly individualWorkShopList =
    "Workshop/GetAdminWorkshopByIdAsync?id={0}&eventYear={1}";
  public readonly InsertWorkShop = "Workshop/CreateWorkshop";

  //Live Session

  public readonly getLivesession = "Live/GetAllLiveSessions?eventYear={0}";
  //live notifications
  public readonly NotificationFunctionUrl =
    "https://azconf-notifications.azurewebsites.net/api/notification"; // "http://localhost:7071/api/notification"

  public readonly UpdateSessionIdInLiveUrl =
    "Live/UpdateSessionIdInLive?trackName={0}&sessionId={1}";

  //Ticket
  public readonly CreateTicket = "Pricing/CreatePricing";
  public readonly getPriceDetails =
    "Pricing/GetPriceDetails?pricetype={0}&isAdmin={1}&eventYear={2}";
  public readonly statusChangeprice =
    "Pricing/StatusChangePrice?id={0}&status={1}";
  public readonly updatePrice = "Pricing/UpdatePrice";

  //Coupon

  public readonly couponcreate = "Coupon/CouponCreate";
  public readonly getCoupan = "Coupon/GetAllCoupons";
  public readonly statusChangeCoupon =
    "Coupon/StatusChangeCoupon?id={0}&status={1}";
  public readonly updateCoupon = "Coupon/CouponUpdate";
  public readonly createFaq = "Faq/CreateFaq";
  public readonly getFaqList = "Faq/FaqList?Type={0}&eventYear={1}";
  public readonly faqUpdate = "Faq/Updatefaq";

  //Attendess

  public readonly GetPaidAttendees = "Ticket/GetPaidAttendeesDetails?eventYear={0}";
  public readonly GetNotPaidAttendees = "Ticket/GetNotPaidAttendeesDetails?eventYear={0}";
  //PaymentGateway
  public readonly getPaymentStatus = "PhonePe/getPaymentStatus?";
  public readonly coupanValidate = "Coupon/Couponvalidate?CouponCode={0}";
  public readonly phonePeCreateBulkTicket = "Ticket/BulkCreatePhonePe";
  public readonly razoryPayCreateBulkTicket = "Ticket/BulkCreateRazoryPay";
  public readonly bulkTicketList = "Payment/BulkTicketPaymentList";
  public readonly paymentStatusCheck = "PhonePe/GetPaymentStatus?transactionId=";
}
