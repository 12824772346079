<app-az-header> </app-az-header>
<app-alert></app-alert>

<app-az-menu></app-az-menu>
<div class="content-wrapper">
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Add Sessionize</h3>
            </div>
            <form [formGroup]="SessionizeForm" (ngSubmit)="addSessionize()">
              <div class="card-body">
                <div class="form-group">
                  <label>Sessionize Sessions URL</label>
                  <input type="text" formControlName="SessionizeSessionURL" id="SessionizeSessionURL"
                  [ngClass]="{ 'is-invalid': submitted && Sessionize.SessionizeSessionURL.errors }" autocomplete="off"
                  class="form-control" placeholder="Sessionize Sessions URL">
                <div *ngIf="submitted && Sessionize.SessionizeSessionURL.errors" class="invalid-feedback">
                  <div *ngIf="Sessionize.SessionizeSessionURL.errors.required">Sessions URL Required</div>
                </div>
                </div>
                <div class="form-group">
                  <label>Sessionize Speakers URL</label>
                  <input type="text" formControlName="SessionizeSpeakerURL" SessionizeSpeakerURL="SessionizeURL"
                  [ngClass]="{ 'is-invalid': submitted && Sessionize.SessionizeSpeakerURL.errors }" autocomplete="off"
                  class="form-control" placeholder="Sessionize Speakers URL">
                <div *ngIf="submitted && Sessionize.SessionizeSpeakerURL.errors" class="invalid-feedback">
                  <div *ngIf="Sessionize.SessionizeSpeakerURL.errors.required">Speakers URL Required</div>
                </div>
                </div>
              </div>
              <!-- /.card-body -->

              <div class="card-footer ">
                <span class="float-right">
                  <button
                    type="submit"

                    class="btn btn-primary"
                  >
                  {{ButtonName}}
                  </button>
                  <button
                    type="submit"
                    (click)="reset()"
                    class="btn btn-danger ml-2"
                  >
                    Reset
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- <app-az-footer></app-az-footer> -->
