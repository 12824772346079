import { Injectable, Injector } from '@angular/core';
import { ApiServicesService } from './Services/api-services.service';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppConfig } from './appConfig/app.config'

@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(private injector: Injector,
    private appConfig: AppConfig
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    
    
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Pragma', 'no-cache');
      if ((request.body as FormData)) {
        headers = headers.append('x-api-key', this.appConfig.authkey);
      }
      else {
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('x-api-key', this.appConfig.authkey);
      }
      
      request = request.clone({
        url: request.url,
        headers
      });
    
    return next.handle(request)
      .pipe(

        tap((event => {

          if (event instanceof HttpResponse) {

            if (event.status === 200) {

              if (event.body['error'] && event.body['errorCode'] && event.body['errorCode'] === 401) {
                alert(event.body['error']);
              }
            }

          }
          return event;

        }),

          error => {

            if (error instanceof HttpErrorResponse && error.status === 401) {

            }
          }));
  }
}

